import { CampaignActStatisticType, CustomerRole, PerformerRole } from '@/entities/CampaignAct';

export const CUSTOMER_ROLE_NAME: Record<CustomerRole, string> = {
    [CustomerRole.DISTRIBUTOR]: 'Рекламораспространитель',
    [CustomerRole.OPERATOR]: 'Оператор рекламной системы',
    [CustomerRole.ADVERTISER]: 'Рекламодатель',
    [CustomerRole.AGENCY]: 'Рекламное агенство'
};

export const PERFORMER_ROLE_NAME: Record<PerformerRole, string> = {
    [CustomerRole.DISTRIBUTOR]: 'Рекламораспространитель',
    [CustomerRole.OPERATOR]: 'Оператор рекламной системы',
    [CustomerRole.ADVERTISER]: 'Рекламодатель',
    [CustomerRole.AGENCY]: 'Рекламное агенство'
};

export const CAMPAIGN_ACT_STATISTIC_TYPE_NAME: Record<CampaignActStatisticType, string> = {
    [CampaignActStatisticType.SITE]: 'Сайт',
    [CampaignActStatisticType.APPLICATION]: 'Приложение',
    [CampaignActStatisticType.INFORMATION_SYSTEM]: 'Информационная система'
};
