import { ActionPromoStatus as ActionPromoStatusEnum } from '../types/ActionPromoStatus';

export const ACTION_PROMO_STATUS: Record<ActionPromoStatusEnum, string> = {
    [ActionPromoStatusEnum.ERROR]: 'Ошибка',
    [ActionPromoStatusEnum.TEMPLATE]: 'Отклонена',
    [ActionPromoStatusEnum.SENT_FOR_PRE_CALCULATION]: 'Отправлено на предрасчет',
    [ActionPromoStatusEnum.PRE_CALCULATION_STARTED]: 'Запущен предрасчет',
    [ActionPromoStatusEnum.PRE_CALCULATION_COMPLETED]: 'Завершен предрасчет',
    [ActionPromoStatusEnum.PRE_CALCULATION_APPROVED]: 'Предрасчет утвержден',
    [ActionPromoStatusEnum.AGREED_AWAITING_ESTABLISHMENT]: 'Согласовано и ожидает заведения',
    [ActionPromoStatusEnum.ACTIVE]: 'Утверждена в ЦВ Протек'
};
