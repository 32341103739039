import { ActionPromoTypeAction, ReportActionType } from '../types';

export const ACTION_PROMO_TYPE_ACTION: Record<ActionPromoTypeAction, string> = {
    [ActionPromoTypeAction.SALE_IN_PRICE]: 'Скидка в цене',
    [ActionPromoTypeAction.BONUS_PACKAGE]: 'Бонусная упаковка'
};

export const ACTION_REPORT_ACTION_TYPE: Record<ReportActionType, string> = {
    [ReportActionType.SALE_IN_PRICE]: 'Скидка в цене',
    [ReportActionType.BONUS_PACKAGE]: 'Бонусная упаковка N+M',
    [ReportActionType.BONUS_PACKAGE_BY_RUBLE]: 'Бонусная упаковка за сумму заказа'
};
