<template>
    <aside @mouseleave="closeMenu" @mouseenter="openMenu">
        <nav :class="['sidebar', { 'sidebar--open': isOpenMenu }]">
            <ul class="sidebar-list">
                <li class="sidebar-list__item logo">
                    <router-link to="/" class="sidebar-list__link">
                        <img src="@/assets/logo.svg" alt="Протек — Центр Внедрения" />
                    </router-link>
                </li>

                <li class="sidebar-list__item list__item--line"></li>

                <li v-for="item in sidebarFirstLevel" :key="item.id" v-bind="item.propsLi">
                    <component :is="item.tag" v-bind="item.propsContent">
                        <PIcon :name="item.iconName" />
                        <span>{{ item.text }}</span>
                    </component>
                </li>
            </ul>
        </nav>

        <div v-for="submenu of sidebarSecondLevel" :key="submenu.id" v-bind="submenu.propsRoot">
            <div class="submenu__wrapper">
                <ul class="submenu-list">
                    <li v-for="item of submenu.children" :key="`${submenu.id}_${item.id}`" class="submenu-list__item">
                        <div v-if="item.type === 'caption'" class="submenu-list__link submenu-list__link--title">
                            <span>{{ item.text }}</span>
                        </div>
                        <router-link v-else :to="item.to!" class="submenu-list__link">
                            <PIcon :name="item.iconName!" />
                            <span>{{ item.text }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>

        <div v-show="isInitializing" class="initializing">
            <div v-loading.skeleton="true" style="--item: 1" />
            <div v-loading.skeleton="true" style="--item: 2" />
            <div v-loading.skeleton="true" style="--item: 3" />
            <div v-loading.skeleton="true" style="--item: 4" />
            <div v-loading.skeleton="true" style="--item: 5" />
            <div v-loading.skeleton="true" style="--item: 6" />
        </div>
    </aside>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { PIcon } from '@/shared/ui';
import { PermissionDictionary } from '@/shared/model/types/Permission';
import { useRoute } from 'vue-router';
import type { IconName } from '@/shared/model/types/Icons';
import { usePermissionsCampaignAct } from '@/entities/CampaignAct';
import { usePermissionsBookingCalendar } from '@/features/BookingCalendar';
import { usePermissionsCampaignReport } from '@/entities/CampaignReport';
import { usePermissionsAdvertisingPlan } from '@/pages/AdvertisingPlan/AdvertisingPlan';
import { usePermissionsCampaign } from '@/entities/Campaign';
import { usePermissionsStatisticsCategory } from '@/pages/StatisticsAdjustments/StatisticsCategory';
import { usePermissionsStatisticsKeywords } from '@/features/StatisticsKeywords';
import { usePermissionsActionPromo } from '@/entities/ActionPromo';
import { usePermissionsAsyncReport } from '@/entities/B2BAsyncReport';
import { useUserStore } from '@/shared/model/store/UserStore';
import { useAccessStore } from '@/shared/model/store/AccessStore';
import { usePermissionsBooking } from '@/entities/Booking';
import { usePermissionsPromotion } from '@/entities/Promotion';

interface SidebarSubCaption {
    type: 'caption';
    text: string;
}

interface SidebarSubLink {
    type?: 'link';
    text: string;
    icon: IconName;
    to: { name: string };
}

type SidebarSubItem = SidebarSubCaption | SidebarSubLink;

interface SidebarItemBase {
    text: string;
    icon: IconName;
    slug: string;
    children?: SidebarSubItem[];
}

interface SidebarItemDiv extends SidebarItemBase {
    type?: 'div';
    isActive: boolean;
    to?: undefined;
}

interface SidebarItemLink extends SidebarItemBase {
    type: 'link';
    to: { name: string };
}

type SidebarItem = SidebarItemDiv | SidebarItemLink;

const accessStore = useAccessStore();
const route = useRoute();
const isOpenMenu = ref<boolean>(false);
const activeItem = ref<string>('');

const isInitializing = computed<boolean>(() => {
    const accessStore = useAccessStore();
    return accessStore.isInitializing;
});

const onActiveItem = (name: string): void => {
    activeItem.value = name;
};

const highlightItem = (name: string): boolean => {
    return activeItem.value === name;
};

const openMenu = () => {
    if (isInitializing.value) {
        return;
    }

    isOpenMenu.value = true;
};

const closeMenu = () => {
    isOpenMenu.value = false;
    activeItem.value = String(route.matched[0]?.name ?? '') || 'unknown';
};

onMounted(() => {
    activeItem.value = String(route.matched[0]?.name ?? '') || 'unknown';
});

const userStore = useUserStore();
const checkPermission = accessStore.checkPermission;
const isProtekCompany = accessStore.isProtekCompany;

const getReport = (): SidebarItem => {
    const children: SidebarSubItem[] = [
        {
            type: 'caption',
            text: 'Отчеты'
        },
        {
            icon: 'clients',
            to: { name: 'reportPartnersShipments' },
            text: 'Отгрузки по клиентам'
        },
        {
            icon: 'city',
            to: { name: 'reportDepartmentsShipments' },
            text: 'Отгрузки по сбытовым подразделениям'
        },
        {
            icon: 'stocs',
            to: { name: 'reportRegionsAndCitiesShipments' },
            text: 'Отгрузки по регионам и городам'
        },
        {
            icon: 'region-all',
            to: { name: 'reportStockByBranches' },
            text: 'Товарный запас по филиалам'
        }
    ];

    if (
        checkPermission(
            PermissionDictionary.PURCHASING_DEPARTMENT_REPORT_MANAGEMENT,
            PermissionDictionary.B2B_STOCK_REPORT_MANAGEMENTT,
            PermissionDictionary.MANAGING_STOCK_REPORTS_HEALTHCITY,
            PermissionDictionary.MANAGING_OSO_REPORTS
        )
    ) {
        children.push({
            icon: 'unlock',
            to: { name: 'reportPackages' },
            text: 'Управление доступом к отчетности'
        });
    }

    return {
        slug: 'report',
        text: 'Отчеты',
        icon: 'statistic',
        isActive: route.path.includes('report/'),
        children
    };
};

const getAdvertisingCabinet = (): SidebarItem => {
    const children: SidebarSubItem[] = [
        {
            type: 'caption',
            text: 'Рекламный кабинет'
        }
    ];

    if (checkPermission(PermissionDictionary.ADVERTISING_PLACEMENTS)) {
        children.push({
            icon: 'folder-open',
            to: { name: 'placements' },
            text: 'Рекламные размещения'
        });
        children.push({
            icon: 'folder-plus',
            to: { name: 'placementView' },
            text: 'Добавить размещение'
        });
    }

    if (usePermissionsBooking().canRead()) {
        children.push({
            icon: 'addition',
            to: { name: 'bookings-table' },
            text: 'Бронирование рекламных кампаний'
        });
    }

    if (usePermissionsBooking().canRead()) {
        children.push({
            icon: 'add-to-queue',
            to: { name: 'multibookings' },
            text: 'Мультибронирование рекламных кампаний'
        });
    }

    if (usePermissionsCampaign().canManage()) {
        children.push({
            icon: 'analysis',
            to: { name: 'campaigns' },
            text: 'Рекламные кампании'
        });
    }

    if (usePermissionsCampaignAct().canManage()) {
        children.push({
            icon: 'email-report',
            to: { name: 'campaign-act-reports' },
            text: 'Ежемесячный отчет по РК'
        });
    }

    if (usePermissionsAdvertisingPlan().canManage()) {
        children.push({
            icon: 'planning',
            to: { name: 'advertising-plan' },
            text: 'План по показам'
        });
    }

    if (usePermissionsBookingCalendar().canManage()) {
        children.push({
            icon: 'date', // size 28
            to: { name: 'booking-calendar' },
            text: 'Календарь бронирования'
        });
    }

    if (usePermissionsCampaignReport().canRead()) {
        children.push({
            icon: 'bar-chart-square',
            to: { name: 'campaignReport' },
            text: 'Отчёт по рекламным кампаниям'
        });
    }

    if (checkPermission(PermissionDictionary.REPORT_ADVERTISING_PLACEMENTS)) {
        children.push({
            icon: 'origin',
            to: { name: 'placementReportsCapacity' },
            text: 'Отчёт по рекламным размещениям'
        });
    }

    if (usePermissionsStatisticsCategory().canManage() && usePermissionsStatisticsKeywords().canManage()) {
        children.push({
            icon: 'slider-03',
            to: { name: 'statistics-adjustments' },
            text: 'Статистика для корректировок'
        });
    }

    if (usePermissionsAsyncReport().canAdGoodsReport(userStore.companyWabcCode)) {
        children.push({
            icon: 'bar-chart-square',
            to: { name: 'sales-report' },
            text: 'Отчёт по продажам'
        });
    }

    return {
        slug: 'advertisingCabinet',
        text: 'Рекламный кабинет',
        icon: 'megaphone-add',
        isActive: route.path.includes('advertising-cabinet/'),
        children
    };
};

const getProducts = (): SidebarItem => {
    const children: SidebarSubItem[] = [];

    if (checkPermission(PermissionDictionary.GOODS_SECTION)) {
        children.push(
            ...([
                {
                    type: 'caption',
                    text: 'Товары'
                },
                {
                    icon: 'folder-open',
                    to: { name: 'products-table' },
                    text: 'Список товаров'
                }
            ] as const)
        );
    }

    if (checkPermission(PermissionDictionary.PRICES)) {
        children.push({
            icon: 'calc',
            to: { name: 'productPrices' },
            text: 'Цены'
        });
    }

    if (checkPermission(PermissionDictionary.GOODS_SECTION)) {
        children.push({
            icon: 'folder-plus',
            to: { name: 'createProductForm' },
            text: 'Добавить товар'
        });

        children.push({
            icon: 'folder-plus',
            to: { name: 'uploadGoodForm' },
            text: 'Добавить товары'
        });
    }

    if (checkPermission(PermissionDictionary.SECTION_SETTING_CATEGORIES)) {
        children.push({
            icon: 'categories',
            to: { name: 'productCategories' },
            text: 'Настройка категорий'
        });
    }

    return {
        slug: 'products',
        text: 'Товары',
        icon: 'pills',
        isActive: route.path.includes('products/'),
        children
    };
};

const getStocks = (): SidebarItem => {
    const children: SidebarSubItem[] = [
        {
            type: 'caption',
            text: 'Акции'
        }
    ];

    if (usePermissionsActionPromo().canCreate(userStore.companyWabcCode)) {
        children.push({
            icon: 'folder-open',
            to: { name: 'stocks-table' },
            text: 'Список акций'
        });
    }

    if (checkPermission(PermissionDictionary.PROTEK_STOCK_REPORTS)) {
        children.push({
            icon: 'grid',
            to: { name: 'reportStock' },
            text: 'Отчеты по акциям B2B'
        });
    }

    if (usePermissionsActionPromo().canOpenPage()) {
        children.push({
            icon: 'grid',
            to: { name: 'reportStockZdravcity' },
            text: 'Отчёты Здравсити'
        });
    }

    if (usePermissionsPromotion().canRead()) {
        children.push({
            icon: 'clients',
            to: { name: 'promotions-calendar' },
            text: 'Календарь акций'
        });
    }

    return {
        slug: 'stocks',
        text: 'Акции',
        icon: 'tag-outline',
        isActive: route.path.includes('stocks/'),
        children
    };
};

const getShippingAdjustment = (): SidebarItem => {
    const children: SidebarSubItem[] = [
        {
            type: 'caption',
            text: 'Корректировки по отгрузке'
        }
    ];

    if (checkPermission(PermissionDictionary.SHIPPING_ADJUSTMENTS)) {
        children.push({
            icon: 'folder-open',
            to: { name: 'plan-suppliers' },
            text: 'План поставщиков'
        });
    }

    if (checkPermission(PermissionDictionary.SHIPPING_ADJUSTMENTS)) {
        children.push({
            icon: 'folder-open',
            to: { name: 'range-suppliers' },
            text: 'Ассортимент поставщика'
        });
    }

    if (checkPermission(PermissionDictionary.SHIPPING_ADJUSTMENTS)) {
        children.push({
            icon: 'folder-open',
            to: { name: 'shipping-documents' },
            text: 'Документы отгрузки'
        });
    }

    return {
        slug: 'shipping-adjustments',
        text: 'Корректировки по отгрузке',
        icon: 'news',
        isActive: route.path.includes('shipping-adjustments/'),
        children
    };
};

const getPlanReports = (): SidebarItem => {
    const children: SidebarSubItem[] = [
        {
            type: 'caption',
            text: 'Совместное планирование'
        }
    ];

    if (checkPermission(PermissionDictionary.JOINT_PLANNING_PLAN_PROTEK)) {
        children.push({
            icon: 'folder-open',
            to: { name: 'planProtekReport' },
            text: 'Протек'
        });
    }

    if (checkPermission(PermissionDictionary.JOINT_PLANNING_VENDOR_PLAN)) {
        children.push({
            icon: 'folder-open',
            to: { name: 'planSupplierReport' },
            text: 'План поставщика'
        });
    }

    return {
        slug: 'plan-reports',
        text: 'Совместное планирование',
        icon: 'date',
        isActive: route.path.includes('plan-reports/'),
        children
    };
};

const sidebarItems = computed<SidebarItem[]>(() => {
    if (isInitializing.value) {
        return [];
    }

    const items: SidebarItem[] = [];

    items.push(getReport());

    if (
        checkPermission(
            PermissionDictionary.GOODS_SECTION,
            PermissionDictionary.SECTION_SETTING_CATEGORIES,
            PermissionDictionary.PRICES
        )
    ) {
        items.push(getProducts());
    }

    if (checkPermission(PermissionDictionary.ORDERS_SECTION)) {
        items.push({
            type: 'link',
            slug: 'orders',
            to: { name: 'orders' },
            text: 'Заказы',
            icon: 'repeat'
        });
    }

    const isVisibleAdvertisingCabinet = (): boolean => {
        const permissions = [
            PermissionDictionary.ADVERTISING_PLACEMENTS,
            PermissionDictionary.REPORT_ADVERTISING_PLACEMENTS
        ];
        const accesses = [
            usePermissionsBookingCalendar().canManage,
            usePermissionsCampaignReport().canRead,
            usePermissionsAdvertisingPlan().canManage,
            usePermissionsCampaign().canManage,
            usePermissionsAsyncReport().canManage,
            usePermissionsBooking().canRead
        ];

        const hasPermissions = checkPermission(...permissions);
        const hasAccess = accesses.some(cb => cb());
        return hasPermissions || hasAccess;
    };

    if (isVisibleAdvertisingCabinet()) {
        items.push(getAdvertisingCabinet());
    }

    if (
        checkPermission(PermissionDictionary.PROTEK_STOCK_REPORTS) ||
        usePermissionsActionPromo().canReadB2C(userStore.companyWabcCode) ||
        usePermissionsActionPromo().canCreate(userStore.companyWabcCode) ||
        usePermissionsPromotion().canRead()
    ) {
        items.push(getStocks());
    }

    if (checkPermission(PermissionDictionary.SHIPPING_ADJUSTMENTS)) {
        items.push(getShippingAdjustment());
    }

    if (
        checkPermission(
            PermissionDictionary.JOINT_PLANNING_PLAN_PROTEK,
            PermissionDictionary.JOINT_PLANNING_VENDOR_PLAN
        )
    ) {
        items.push(getPlanReports());
    }

    items.push({
        type: 'link',
        to: { name: 'productPromotion' },
        slug: 'productPromotion',
        text: 'Продвижение товаров',
        icon: 'megaphone'
    });

    if (checkPermission(PermissionDictionary.SUPPLIERS) && isProtekCompany) {
        items.push({
            type: 'link',
            to: { name: 'companies' },
            slug: 'companies',
            text: 'Поставщики',
            icon: 'manufacturing'
        });
    }

    if (
        checkPermission(PermissionDictionary.USERS_ADMIN_OWN_COMPANY, PermissionDictionary.USERS_ADMIN_PARTNER_COMPANY)
    ) {
        items.push({
            type: 'link',
            to: { name: 'usersTable' },
            slug: 'users',
            text: 'Сотрудники компании',
            icon: 'users'
        });
    }

    return items;
});

const sidebarFirstLevel = computed(() => {
    return sidebarItems.value.map(item => {
        let contentClasses: Record<string, boolean> = {
            'sidebar-list__link': true,
            highlight: highlightItem(item.slug)
        };
        if (item.type !== 'link') {
            contentClasses = {
                ...contentClasses,
                active: item.isActive,
                'highlight--arrow': isOpenMenu.value && highlightItem(item.slug)
            };
        }
        return {
            id: item.slug,
            propsLi: {
                class: 'sidebar-list__item',
                onClick: () => onActiveItem(item.slug)
            },
            tag: item.type === 'link' ? 'router-link' : 'div',
            propsContent: {
                class: contentClasses,
                to: item.to
            },
            iconName: item.icon,
            text: item.text
        };
    });
});

const sidebarSecondLevel = computed(() => {
    return sidebarItems.value
        .filter(item => item.children?.length)
        .map((item, i) => {
            return {
                id: item.slug,
                propsRoot: {
                    class: {
                        submenu: true,
                        'submenu--open': isOpenMenu.value && highlightItem(item.slug)
                    }
                },
                children: item.children?.map(child => {
                    if (child.type === 'caption') {
                        return {
                            id: i,
                            ...child
                        };
                    }

                    return {
                        id: i,
                        to: child.to,
                        iconName: child.icon,
                        text: child.text,
                        type: child.type
                    };
                })
            };
        });
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/mixin.scss';

$sidebar: '.sidebar';
$sidebarOpen: '.sidebar--open';

aside {
    position: relative;
}

.sidebar {
    position: fixed;
    top: 0;
    z-index: var(--z-index-side-bar);

    width: var(--sidebar-width);
    background-color: var(--sidebar-dark);

    border-top-right-radius: 2.5rem;
    padding: 0.7rem 0;
    height: 100vh;

    transition: 0.2s ease-in-out;

    &--open {
        background-color: var(--sidebar);
        width: var(--sidebar-width-expanded);
        @include scrollbar-hover();
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.logo {
    width: 5.6rem;
    display: inline-block;
    overflow: hidden;

    a.active {
        background-color: transparent;
    }

    #{$sidebarOpen} & {
        width: 100%;
    }
}

.sidebar-list {
    display: flex;
    flex-direction: column;
    min-height: 96vh;

    &__item {
        &:not(:first-child):not(:last-child) {
            margin-bottom: 1.4rem;
        }

        &--line {
            overflow: hidden;
            height: 1px;
            width: 50%;
            margin-top: 0.2rem;
            margin-left: 2rem;
            background-color: #393e4d;

            #{$sidebarOpen} & {
                width: calc(100% - 2rem);
            }
        }

        &--fill-bottom-space {
            margin-top: auto;
        }
    }

    &__link {
        display: grid;
        align-items: center;
        grid-template-columns: 24px 1fr;
        grid-gap: 1.6rem;
        font-weight: bold;
        cursor: pointer;

        text-decoration: none;
        padding: 1.7rem 3rem;
        color: var(--text-color-light);
        white-space: nowrap;
        transition: 0.1s 0.4ms linear;

        &:hover,
        &:focus {
            color: var(--white);
        }

        &.active {
            color: var(--white);
        }

        &.highlight {
            position: relative;
            background-image: var(--primary-gradient);
            color: var(--white);
            transition: 0.1s 0.4ms linear;

            &--arrow::after {
                content: '';
                left: 100%;
                top: calc(50% - 10px);
                position: absolute;
                z-index: 1;
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-left-color: var(--primary-lighten);
            }
        }

        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
            cursor: no-drop !important;
        }

        span {
            opacity: 0;
            overflow: hidden;

            #{$sidebarOpen} & {
                opacity: 1;
            }
        }
    }
}

.submenu {
    position: fixed;
    left: calc(var(--sidebar-width-expanded) - 20px);
    top: 0;
    bottom: 0;
    z-index: var(--z-index-side-bar-submenu);
    width: 320px;
    padding-top: 1.8rem;
    border-top-right-radius: 2.5rem;
    background-color: var(--sidebar-submenu);
    display: none;

    &::after {
        border-top-right-radius: 2.5rem;
        background-color: var(--sidebar);
    }

    &--open {
        display: block;
        animation: 0.2s slide-right 0.2s ease-in-out backwards;
    }

    @keyframes slide-right {
        from {
            opacity: 0;
            transform: translateX(-50px);
        }

        to {
            opacity: 1;
            transform: translate(0);
        }
    }

    &__wrapper {
        position: relative;
        overflow-y: auto;
        height: calc(100vh - 3rem);
        padding: 3rem 0 13rem 2rem;
        margin-right: 0.4rem;
        @include scrollbar-hover();
    }

    &-list {
        margin: 0 0 2rem;
        padding: 0;
        list-style: none;
        display: grid;
        grid-gap: 2rem;

        & + &::before {
            content: '';
            display: block;
            height: 1px;
            margin-top: 1rem;
            background-color: var(--border-light);
        }

        &__link {
            padding: 0 2.2rem 0 2.8rem;
            display: grid;
            align-items: center;
            grid-template-columns: 24px 1fr;
            grid-gap: 1.6rem;
            color: var(--text-color);
            font-weight: 500;
            font-size: var(--text-size-14);

            &--title {
                font-weight: 700;
                padding-bottom: 0;
                margin-bottom: 1rem;
                font-size: var(--text-size-16);
                grid-template-columns: 1fr;

                &:hover {
                    color: var(--text-color) !important;
                }
            }

            &:hover,
            &:focus {
                color: var(--primary);
            }

            &.active {
                color: var(--primary-light);
            }

            svg {
                opacity: 0.9;
            }

            &[disabled] {
                opacity: 0.5;
                pointer-events: none;
                cursor: no-drop !important;
                color: var(--text-color);
            }
        }
    }
}

.manager-info {
    overflow: hidden;
    padding: 1.7rem 2.8rem;
    color: var(--white);
    display: none;
    grid-gap: 1.2rem;

    &__title {
        font-size: 1.4rem;
        color: var(--text-color-light);
        white-space: nowrap;
    }

    &__name {
        font-weight: bold;
        line-height: 1.4;
        white-space: nowrap;
    }

    &__tel {
        font-weight: bold;
        color: var(--text-color-light);
        text-decoration: none;
    }

    #{$sidebarOpen} & {
        display: grid;
    }
}

.initializing {
    position: fixed;
    inset: 88px auto auto;
    width: var(--sidebar-width);
    z-index: var(--z-index-side-bar);
    display: grid;
    grid-template-rows: repeat(14, 1fr);
    grid-gap: 2.2rem;
    justify-items: center;
    align-items: end;
    background-color: var(--sidebar-dark);

    > div {
        width: 40px;
        height: 40px;
        border-radius: 12px;
        opacity: 0.1;
        animation: fade-in 0.2s ease-in-out calc(var(--item) * 0.1s) forwards;

        &:first-of-type {
            margin-top: 10px;
        }
    }

    @keyframes fade-in {
        to {
            opacity: 0.2;
        }
    }
}
</style>
