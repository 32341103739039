export type dateISO = string; // Date.prototype.toISOString()

export type Nullable<T> = { [K in keyof T]?: T[K] | null };

export type Brand<Base, Branding, ReservedName extends string = '__type__'> = Base & {
    [K in ReservedName]: Branding;
} & { __witness__: Base };

/**
 * Вспомогательная функция, используемая в контексте TypeScript для обеспечения полного покрытия
 * всех возможных случаев в операторе switch для объединённого типа.
 *
 * @returns {never} Вызывает ошибку при вызове, так как указывает, которое
 *                  не должно появляться, если все типы обработаны правильно.
 */
export function assertNever(value: never): never {
    throw new Error(`Unexpected value:  ${value}`);
}
