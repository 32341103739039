import type { DirectiveBinding, ObjectDirective } from 'vue';
import './style.scss';

/**
 * Директива для отображения индикатора загрузки
 * @example <div v-loading="visible">...</div>
 */
function bindLoading(element: HTMLElement, binding: DirectiveBinding<boolean>) {
    const {
        value,
        modifiers: { skeleton, fullscreen }
    } = binding;
    if (skeleton) {
        element.classList.toggle('skeleton', value);
    } else if (fullscreen) {
        element.classList.toggle('fullscreen', value);
    } else {
        element.classList.toggle('loading', value);
    }
}

export default {
    beforeMount: bindLoading,
    updated: bindLoading,
    unmounted(element: HTMLElement) {
        element.classList.remove('loading', 'skeleton', 'fullscreen');
    }
} as ObjectDirective<HTMLElement, boolean>;
