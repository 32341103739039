<template>
    <section class="layout">
        <PSidebar v-if="isCompanyActive" />
        <main>
            <PHeader />
            <slot />
        </main>
    </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import PHeader from '@/layout/PHeader.vue';
import PSidebar from '@/layout/PSidebar.vue';
import { useUserStore } from '@/shared/model/store/UserStore';
import { CompanyStatus } from '@/shared/model/types/Company';

const userStore = useUserStore();
const companyStatus = computed(() => userStore.company?.status);
const isCompanyActive = computed<boolean>(() => companyStatus.value === CompanyStatus.ACTIVE);
</script>
