<template>
    <template v-for="item in items" :key="item.id">
        <PModal
            :model-value="true"
            :width="item.width ? item.width : 500"
            :closed-on-backdrop="false"
            :visible-close-button="false"
            :backdrop-z-index="item.backdropZIndex"
            @update:model-value="!$event && item.cancel()"
        >
            <component :is="item.component" v-bind="item.props" @confirm="item.confirm" @cancel="item.cancel" />
        </PModal>
    </template>
</template>

<script lang="ts" setup>
import { useConfirmStore } from './useConfirmStore';
import PModal from '@/shared/ui/PModal/PModal.vue';

const { items } = useConfirmStore();
</script>
