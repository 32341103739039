import type { Relation, RelationRule } from '@/shared/api/types/JSON_API';

export const getRelationships = (data: Record<string, Relation[] | Relation | null>, rules: RelationRule[]) => {
    return rules.reduce<Record<string, number[] | number | null>>((acc, rule) => {
        acc[rule.rename] = [];

        if (rule.type === 'single') {
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'Relation | R... Remove this comment to see the full error message
            acc[rule.rename] = data[rule.name]?.id ?? null;
        } else {
            if (Array.isArray(data[rule.name])) {
                // @ts-expect-error TS(2339): Property 'map' does not exist on type 'Relation | ... Remove this comment to see the full error message
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                acc[rule.rename] = data[rule.name].map((item: any) => item.id);
            } else {
                acc[rule.rename] = [];
            }
        }

        return acc;
    }, {});
};
