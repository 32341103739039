export enum ActionPromoStatus {
    ERROR = -1, // Расчет по акции завершен с ошибкой
    TEMPLATE = 9, // Акция создана, ожидает отправки для предварительного расчета
    SENT_FOR_PRE_CALCULATION = 10, // Отправить на предрасчет
    PRE_CALCULATION_STARTED = 12, // Запустить предрасчет (запущена процедура sk2.pkg_pp.p_main(id_action))
    PRE_CALCULATION_COMPLETED = 13, // Получить предрасчет
    PRE_CALCULATION_APPROVED = 14, // Утвердить предрасчет или отклонить
    AGREED_AWAITING_ESTABLISHMENT = 15, // согласовать заведение акции в ПО Скидки или отправить на доработку
    ACTIVE = 20 // заведена в ПО скидки (запуск процедуры sk2.pkg_pp.p_main(id_action) по изменению статуса с 15 на 20 должен производиться автоматически
}
