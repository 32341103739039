import { ability } from '@/shared/lib/casl/casl';
import { subject } from '@casl/ability';
import type { CompanyProtekId } from '@/shared/model/types/Company';

export const usePermissions = () => {
    const { can } = ability;

    const canCreate = (companyProtekId: CompanyProtekId | null = null) => {
        let condition: Record<string, unknown> = {};
        if (companyProtekId) {
            condition = {
                provider_code: [companyProtekId]
            };
        }

        return can('create', subject('PromoAction', condition));
    };

    const canRead = (companyProtekId: CompanyProtekId | null = null) => {
        let condition: Record<string, unknown> = {};
        if (companyProtekId) {
            condition = {
                supplier_code: [companyProtekId]
            };
        }

        return can('read', subject('PromoAction', condition));
    };

    const canReadB2C = (companyProtekId: CompanyProtekId | null = null) => {
        let condition: Record<string, unknown> = {};
        if (companyProtekId) {
            condition = {
                supplier_code: [companyProtekId]
            };
        }

        return can('read', subject('PromoActionZdravcity', condition));
    };

    const canOpenPage = () => {
        return can('read', 'PromoActionZdravcityVisibility');
    };

    return {
        canCreate,
        canRead,
        canReadB2C,
        canOpenPage
    };
};
