import type { Staff, StaffId } from '../model/Staff';
import client from '@/shared/api/client';
import * as JsonApiDefaultMapper from '@/shared/api/mapper/JsonApiDefaultMapper';

interface StaffQueryParams {
    id?: StaffId;
    fullName?: string;
    cdColl?: number;
}

const convertParams = (params: StaffQueryParams): Record<string, string | number> => {
    const result: Record<string, string | number> = {};
    if (params.id) {
        result.id = params.id;
    }

    if (params.fullName) {
        result[`fullName[like]`] = params.fullName;
    }

    if (params.cdColl) {
        result.cdColl = params.cdColl;
    }

    return result;
};

export const getStaff = async (params: StaffQueryParams) => {
    const response = await client.get('/api/tmp-staff', { params: convertParams(params) });
    const staffs: Staff[] = [];
    const data = Array.isArray(response.data.data) ? response.data.data : [];
    for (const item of data) {
        staffs.push(JsonApiDefaultMapper.toClient<Staff>(item));
    }
    return staffs;
};
