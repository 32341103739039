import { NetworkError } from './NetworkError';

export class UserAlreadyExists extends NetworkError {
    readonly name: string = 'UserAlreadyExists';

    constructor(
        message: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        readonly response: any
    ) {
        super(message, response);
    }
}
