import { ref } from 'vue';

export function useDnDFiles({ addFile }: { addFile: (file: File) => void }) {
    const isDropActive = ref<boolean>(false);

    const drop = (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        isDropActive.value = false;

        // @ts-expect-error FIXME
        if (event.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            // @ts-expect-error FIXME
            for (const item of event.dataTransfer.items) {
                // If dropped items aren't files, reject them
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    if (file) {
                        addFile(file);
                    }
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            // @ts-expect-error FIXME
            for (const file of event.dataTransfer.files) {
                addFile(file);
            }
        }
    };

    const dragover = (event: DragEvent) => {
        event.preventDefault();
        isDropActive.value = true;
    };

    const dragleave = () => {
        isDropActive.value = false;
    };

    return {
        isDropActive,
        drop,
        dragover,
        dragleave
    };
}
