import * as yup from 'yup';
import type { ISchema } from 'yup';
import type { Pagination } from '@/shared/model/types/Pagination';

export const JSONRPCSchema = yup.object().shape({
    id: yup.string().uuid().required(),
    jsonrpc: yup.string().matches(/2\.0/),
    result: yup.object()
});

export const getJSONRPCReportSchema = (dataSchema: ISchema<unknown>) => {
    return JSONRPCSchema.shape({
        result: yup
            .object()
            .transform(value => {
                return {
                    ...value,
                    data: Array.isArray(value.data) ? value.data : []
                };
            })
            .shape({
                data: yup.array().of(dataSchema).default([]),
                totalCount: yup.number().required(),
                totalPages: yup.number().required()
            })
    });
};

export const getJSONRPCPagination = (params: {
    totalCount: number;
    totalPages: number;
    data: unknown[];
    itemsPerPage: number;
    currentPage: number;
}): Pagination => {
    return {
        totalItems: params.totalCount,
        currentPageItemsCount: params.data.length,
        itemsPerPage: params.itemsPerPage || params.data.length,
        totalPages: params.totalPages,
        currentPage: params.currentPage || 1
    };
};
