import * as amplitude from '@amplitude/analytics-browser';
import type { Router } from 'vue-router';
import { snakeCase } from 'lodash';
import { AMPLITUDE_API_KEY } from '@/shared/config/env';

interface EventAmplitude {
    type: string;
    properties?: Record<string, unknown>;
}

export default class Amplitude {
    private static initiated = false;

    static init(): void {
        const options: Record<string, string | number> = {
            minIdLength: 1
        };

        if (AMPLITUDE_API_KEY) {
            Amplitude.initiated = true;
            amplitude.init(AMPLITUDE_API_KEY, '', options);
        }
    }

    static setUserId(id: string): void {
        if (!Amplitude.initiated) {
            return;
        }

        amplitude.setUserId(id);
    }

    static logEvent(event: EventAmplitude) {
        if (!Amplitude.initiated) {
            return;
        }

        const properties: Record<string, unknown> = {};
        for (const [key, value] of Object.entries(event.properties ?? {})) {
            properties[snakeCase(key)] = value;
        }

        amplitude.logEvent(event.type, properties);
    }

    static pageView(router: Router, payload: Record<string, unknown>) {
        if (!Amplitude.initiated) {
            return;
        }

        const params: Record<string, unknown> = { ...payload };
        for (const [key, value] of Object.entries(router.currentRoute.value.params)) {
            params[snakeCase(key)] = String(value);
        }
        Amplitude.logEvent({
            type: 'page_view',
            properties: {
                ...params,
                page_title: router.currentRoute.value.meta?.title || document.title,
                page_location: window.location.href,
                page_path: window.location.pathname
            }
        });
    }

    static logout() {
        amplitude.reset();
    }
}
