import client from '@/shared/api/client';
import type { CompanyId } from '@/shared/model/types/Company';
import { generateUUID } from '@/utils';
import type { UserId } from '@/shared/model/types/User';

interface AuthResponse {
    accessToken: string;
    companies: CompanyId[];
    isActive: boolean;
    userId: UserId;
}

export const login = async (payload: { username: string; password: string }): Promise<AuthResponse> => {
    const { data } = await client.post('/api/auth/login', payload);
    return data;
};

export async function verifyLink(hash: string): Promise<AuthResponse> {
    const { data } = await client.get('/api/links/check', {
        params: {
            hash
        }
    });
    return data;
}

export async function restorePassword(email: string) {
    const payload = {
        jsonrpc: '2.0',
        method: 'users.restorePassword',
        id: generateUUID(),
        params: {
            email: email
        }
    };
    await client.post('/rpc', payload);
}

export async function resetPassword(userId: UserId) {
    await client.post(`/api/v1/users/${userId}/reset-password`);
}

export async function createInviteLink(userId: UserId) {
    const resolveLocalURL = () => {
        const originURL = window.location.origin;
        const isLocalhost = originURL.includes('localhost');
        const originDevelopURL = new URL(import.meta.env.VITE_DEV_API_URL).origin;
        return isLocalhost ? originDevelopURL : originURL;
    };

    const { data } = await client.post('/api/links', {
        userId,
        baseUrl: resolveLocalURL() + '/invite'
    });
    return data as {
        id: number;
        url: string;
    };
}

/**
 * Отправить ссылку инвайта на почту пользователю
 * Приглашение привязывается на существующую сгенерированную ссылку по linkId
 */
export async function createEmailNotification(linkId: number) {
    const payload = {
        jsonrpc: '2.0',
        method: 'notifications.create',
        id: generateUUID(),
        params: {
            typeKey: 'USER_REGISTRATION_LINK_EMAIL_NOTIFICATION',
            linkId
        }
    };

    await client.post('/rpc', payload);
}

/**
 * Отправить ссылку на почту для последующей регистрации поставщика
 */
export async function sendConfirmationLink(userId: UserId) {
    const payload = {
        jsonrpc: '2.0',
        method: 'users.sendConfirmationLink',
        id: generateUUID(),
        params: {
            userId
        }
    };

    await client.post('/rpc', payload);
}
