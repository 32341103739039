import { type Component, markRaw, ref } from 'vue';
import { generateUUID } from '@/utils';
import PConfirm from '@/shared/ui/PConfirm/PConfirm.vue';

interface ConfirmData {
    component: Component;
    props?: Record<string, unknown>;
    width?: number | string;
    backdropZIndex?: number;
}

interface ConfirmItem<EventData> extends ConfirmData {
    id: string;
    cancel: (event?: EventData) => void;
    confirm: (event?: EventData) => void;
}

const items = ref<ConfirmItem<never>[]>([]);

export const useConfirmStore = () => {
    const remove = (id: string) => {
        items.value = items.value.filter(item => item.id !== id);
    };
    const add = <TData>(
        { component = PConfirm, props = {}, ...rest }: ConfirmData = { component: PConfirm, props: {} }
    ): Promise<{ isConfirmed?: boolean; isCanceled?: boolean; data?: TData }> => {
        const id = generateUUID();

        return new Promise(resolve => {
            const item: ConfirmItem<TData> = {
                id,
                component: markRaw(component),
                props,
                ...rest,
                cancel: event => {
                    resolve({
                        isCanceled: true,
                        data: event
                    });
                    remove(id);
                },
                confirm: event => {
                    resolve({
                        isConfirmed: true,
                        data: event
                    });
                    remove(id);
                }
            };
            items.value.push(item);
        });
    };

    const clear = () => {
        items.value = [];
    };

    return {
        items,
        add,
        clear
    };
};
