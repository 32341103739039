import { CollapseNotify, DownloadFileNotify, InviteNotify, modelPNotification } from '@/shared/ui';

const addNotify = (params: modelPNotification.NotifyData<Record<string, unknown>>) => {
    const notifyStore = modelPNotification.useNotificationStore();
    return notifyStore.add(params);
};

const notifyDefault =
    (type: modelPNotification.DefaultNotify['type']) =>
    (params: Omit<modelPNotification.DefaultNotify, 'type'> | string) => {
        let payload;
        if (typeof params === 'string') {
            payload = { props: { text: params, type } };
        } else {
            const { title, text, width, ...options } = params;

            payload = {
                props: {
                    title,
                    text,
                    width,
                    type
                },
                ...options
            };
        }

        return addNotify(payload);
    };

export const notifyInfo = notifyDefault('info');
export const notifySuccess = notifyDefault('success');
export const notifyWarning = notifyDefault('warning');
export const notifyError = notifyDefault('error');

export function notifyDownload(text: string) {
    return addNotify({
        props: {
            text
        },
        component: DownloadFileNotify,
        duration: 0
    });
}

export function notifyCollapse(params: modelPNotification.CollapseNotify) {
    const { title, text, width, type, ...options } = params;
    return addNotify({
        props: {
            title,
            text,
            width,
            type
        },
        ...options,
        component: CollapseNotify,
        duration: 0
    });
}

export function notifyInvite(params: modelPNotification.InviteNotify) {
    const { email, link, sendEmail, ...options } = params;
    return addNotify({
        props: {
            email,
            link,
            sendEmail
        },
        ...options,
        component: InviteNotify,
        duration: 0
    });
}

export function notifyClear() {
    const notifyStore = modelPNotification.useNotificationStore();
    return notifyStore.clear();
}
