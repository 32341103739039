import { PermissionDictionary } from '@/shared/model/types/Permission';
import type { RouteRecordRaw } from 'vue-router';
import EmptyLayout from '@/layout/EmptyLayout.vue';
import OverflowOverlay from '@/layout/OverflowOverlay.vue';
import { usePermissionsCampaignAct } from '@/entities/CampaignAct';
import { usePermissionsBookingCalendar } from '@/features/BookingCalendar';
import { usePermissionsCampaignReport } from '@/entities/CampaignReport';
import { usePermissionsAdvertisingPlan } from '@/pages/AdvertisingPlan/AdvertisingPlan';
import { usePermissionsCampaign } from '@/entities/Campaign';
import { usePermissionsStatisticsKeywords } from '@/features/StatisticsKeywords';
import { usePermissionsStatisticsCategory } from '@/pages/StatisticsAdjustments/StatisticsCategory';
import { usePermissionsActionPromo } from '@/entities/ActionPromo';
import { useUserStore } from '@/shared/model/store/UserStore';
import { usePermissionsPromotion } from '@/entities/Promotion';
import { usePermissionsBooking } from '@/entities/Booking';

export const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'main',
        redirect: { name: 'profile' }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/Login/Main.vue'),
        meta: {
            title: 'Вход',
            layout: EmptyLayout
        }
    },
    {
        path: '/registration',
        name: 'registration',
        component: () => import('@/pages/Registration/Main.vue'),
        meta: {
            title: 'Регистрация',
            layout: EmptyLayout
        }
    },
    {
        path: '/password',
        name: 'password',
        component: () => import('@/pages/Password/Main.vue'),
        meta: {
            title: 'Восстановление пароля',
            layout: EmptyLayout
        }
    },
    {
        path: '/restore-password',
        name: 'restore-password',
        component: () => import('@/pages/RestorePassword/Main.vue'),
        meta: {
            title: 'Сброс пароля',
            layout: EmptyLayout
        }
    },
    {
        path: '/password-change',
        name: 'password-change',
        component: () => import('@/pages/PasswordChange/Main.vue'),
        meta: {
            title: 'Смена пароля',
            layout: EmptyLayout
        }
    },
    {
        path: '/confirmation',
        name: 'confirmation',
        component: () => import('@/pages/ConfirmationEmail/Main.vue'),
        meta: {
            title: 'Подтверждение почтового адреса',
            layout: EmptyLayout
        }
    },
    {
        path: '/invite',
        name: 'invite',
        component: () => import('@/pages/Invite/Main.vue'),
        meta: {
            title: 'Вход',
            layout: EmptyLayout
        }
    },

    {
        path: '/profile',
        component: () => import('@/pages/Profile/Main.vue'),
        name: 'profile',
        meta: {
            title: 'Профиль',
            isAuth: true
        }
    },
    {
        path: '/company-profile',
        name: 'company-profile',
        component: () => import('@/pages/CompanyProfile/Main.vue'),
        meta: {
            title: 'Регистрация компании',
            isAuth: true
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/pages/Settings/Main.vue'),
        meta: {
            title: 'Настройки',
            isAuth: true,
            permissions: [PermissionDictionary.OPTIONS_SECTION]
        }
    },
    {
        path: '/company-profile/:companyId/:step?',
        name: 'company-profile-update',
        component: () => import('@/pages/CompanyProfile/Main.vue'),
        meta: {
            title: 'Регистрация компании',
            isAuth: true
        }
    },

    {
        path: '/report',
        component: () => import('@/pages/Report/Main.vue'),
        name: 'report',
        redirect: { name: '404' },
        meta: {
            title: 'Отчёты',
            isAuth: true
        },
        children: [
            {
                path: 'shipments-partners',
                component: () => import('@/pages/Report/ShipmentPartners/Main.vue'),
                name: 'reportPartnersShipments',
                meta: {
                    title: 'Отчёт отгрузки по клиентам'
                }
            },
            {
                path: 'shipment-departments',
                component: () => import('@/pages/Report/ShipmentDepartments/Main.vue'),
                name: 'reportDepartmentsShipments',
                meta: {
                    title: 'Отгрузки по сбытовым подразделениям'
                }
            },
            {
                path: 'stock-by-branches',
                component: () => import('@/pages/Report/StockByBranches/Main.vue'),
                name: 'reportStockByBranches',
                meta: {
                    title: 'Товарный запас по филиалам'
                }
            },
            {
                path: 'shipment-cities',
                component: () => import('@/pages/Report/ShipmentCities/Main.vue'),
                name: 'reportRegionsAndCitiesShipments',
                meta: {
                    title: 'Отгрузки по регионам и городам'
                }
            },
            {
                path: 'report-packages',
                component: () => import('@/pages/Report/AccessControl/Main.vue'),
                name: 'reportPackages',
                meta: {
                    title: 'Управление доступом к отчетности',
                    permissions: [
                        PermissionDictionary.PURCHASING_DEPARTMENT_REPORT_MANAGEMENT,
                        PermissionDictionary.B2B_STOCK_REPORT_MANAGEMENTT,
                        PermissionDictionary.MANAGING_STOCK_REPORTS_HEALTHCITY,
                        PermissionDictionary.MANAGING_OSO_REPORTS
                    ]
                },
                children: [
                    {
                        path: 'create/:type',
                        name: 'reportPackagesCreate',
                        meta: {
                            title: 'Добавить пакет управления доступом',
                            permissions: [
                                PermissionDictionary.PURCHASING_DEPARTMENT_REPORT_MANAGEMENT,
                                PermissionDictionary.B2B_STOCK_REPORT_MANAGEMENTT,
                                PermissionDictionary.MANAGING_STOCK_REPORTS_HEALTHCITY,
                                PermissionDictionary.MANAGING_OSO_REPORTS
                            ]
                        },
                        component: () => import('@/pages/Report/AccessControl/Main.vue')
                    },
                    {
                        path: ':id',
                        name: 'reportPackagesUpdate',
                        meta: {
                            title: 'Обновить пакет управления доступом',
                            permissions: [
                                PermissionDictionary.PURCHASING_DEPARTMENT_REPORT_MANAGEMENT,
                                PermissionDictionary.B2B_STOCK_REPORT_MANAGEMENTT,
                                PermissionDictionary.MANAGING_STOCK_REPORTS_HEALTHCITY,
                                PermissionDictionary.MANAGING_OSO_REPORTS
                            ]
                        },
                        component: () => import('@/pages/Report/AccessControl/Main.vue')
                    }
                ]
            }
        ]
    },
    {
        path: '/advertising-cabinet',
        component: () => import('@/pages/AdvertisingCabinet/Main.vue'),
        name: 'advertisingCabinet',
        redirect: { name: '404' },
        meta: {
            title: 'Рекламный кабинет',
            isAuth: true
        },
        children: [
            {
                path: 'placements',
                name: 'placements',
                component: () => import('@/pages/AdvertisingCabinet/Placements/Main.vue'),
                meta: {
                    title: 'Рекламные размещение',
                    permissions: [PermissionDictionary.ADVERTISING_PLACEMENTS]
                }
            },
            {
                path: 'placement/:id?',
                name: 'placementView',
                component: () => import('@/pages/AdvertisingCabinet/PlacementView/Main.vue'),
                meta: {
                    title: 'Редактирование рекламного размещения',
                    permissions: [PermissionDictionary.ADVERTISING_PLACEMENTS]
                }
            },
            {
                path: 'campaigns',
                name: 'campaigns',
                component: () => import('@/pages/AdvertisingCabinet/Campaigns/Main.vue'),
                meta: {
                    title: 'Рекламные кампании',
                    accesses: [usePermissionsCampaign().canManage]
                }
            },
            {
                path: 'campaign-view/:id?',
                name: 'campaignView',
                component: () => import('@/pages/AdvertisingCabinet/CampaignView/Main.vue'),
                meta: {
                    title: 'Рекламные кампании',
                    accesses: [usePermissionsCampaign().canManage]
                }
            },
            {
                path: 'bookings/table',
                name: 'bookings-table',
                component: () => import('@/pages/AdvertisingCabinet/Bookings/BookingsTable/Main.vue'),
                meta: {
                    title: 'Бронирование рекламных кампаний',
                    accesses: [usePermissionsBooking().canRead],
                    layout: OverflowOverlay
                }
            },
            {
                path: 'bookings/form/:id?',
                name: 'booking-form',
                component: () => import('@/pages/AdvertisingCabinet/Bookings/BookingForm/Main.vue'),
                meta: {
                    title: 'Данные бронирования рекламной кампании',
                    accesses: [usePermissionsBooking().canRead]
                }
            },
            {
                path: 'multibookings',
                name: 'multibookings',
                component: () => import('@/pages/AdvertisingCabinet/Bookings/MultiBookings/Main.vue'),
                meta: {
                    title: 'Мультибронирование рекламных кампаний',
                    accesses: [usePermissionsBooking().canRead],
                    layout: OverflowOverlay
                }
            },
            {
                path: 'campaign-report',
                name: 'campaignReport',
                component: () => import('@/pages/AdvertisingCabinet/CampaignReport/Main.vue'),
                meta: {
                    title: 'Отчёт по рекламным кампаниям',
                    accesses: [usePermissionsCampaignReport().canRead]
                }
            },
            {
                path: 'campaign-report/:id',
                name: 'campaignReportDetail',
                component: () => import('@/pages/AdvertisingCabinet/CampaignReport/Detail/Main.vue'),
                meta: {
                    title: 'Отчёт по рекламной кампании',
                    accesses: [usePermissionsCampaignReport().canRead]
                }
            },
            {
                path: 'placements-report',
                name: 'placementReportsCapacity',
                component: () => import('@/pages/AdvertisingCabinet/PlacementReport/Main.vue'),
                meta: {
                    title: 'Отчет по рекламным размещениям',
                    permissions: [PermissionDictionary.REPORT_ADVERTISING_PLACEMENTS]
                }
            },
            {
                path: 'placements-report/:placementAdId',
                name: 'placementReportByAdId',
                component: () => import('@/pages/AdvertisingCabinet/PlacementReport/Detail/Main.vue'),
                meta: {
                    title: 'Отчет по рекламному размещению',
                    permissions: [PermissionDictionary.REPORT_ADVERTISING_PLACEMENTS]
                }
            },
            {
                path: 'advertising-plan',
                component: () => import('@/pages/AdvertisingPlan/Main.vue'),
                name: 'advertising-plan',
                meta: {
                    title: 'План по показам',
                    isAuth: true,
                    accesses: [usePermissionsAdvertisingPlan().canManage]
                }
            },
            {
                path: 'booking-calendar',
                component: () => import('@/pages/BookingCalendar/Main.vue'),
                name: 'booking-calendar',
                meta: {
                    title: 'Календарь бронирования',
                    isAuth: true,
                    accesses: [usePermissionsBookingCalendar().canManage]
                }
            },
            {
                path: 'campaign-report-act',
                component: () => import('@/pages/AdvertisingCabinet/CampaignReportAct/Acts.vue'),
                name: 'campaign-act-reports',
                meta: {
                    title: 'Акты',
                    isAuth: true,
                    accesses: [usePermissionsCampaignAct().canManage]
                }
            },
            {
                path: 'campaign-report-act/agencies',
                component: () => import('@/pages/AdvertisingCabinet/CampaignReportAct/Agencies.vue'),
                name: 'campaign-act-agencies',
                meta: {
                    title: 'Компании по которым не отправлен отчёт',
                    isAuth: true,
                    accesses: [usePermissionsCampaignAct().canManage]
                }
            },
            {
                path: 'campaign-report-act/companies/:campaignActType',
                component: () => import('@/pages/AdvertisingCabinet/CampaignReportAct/Companies.vue'),
                name: 'campaign-act-reports-companies',
                meta: {
                    title: 'Компании по которым не отправлен отчёт',
                    isAuth: true,
                    accesses: [usePermissionsCampaignAct().canManage]
                }
            },
            {
                path: 'campaign-report-act/campaigns',
                component: () => import('@/pages/AdvertisingCabinet/CampaignReportAct/Campaigns.vue'),
                name: 'campaign-act-reports-self-advertisement',
                meta: {
                    title: 'Рекламные кампании',
                    isAuth: true,
                    accesses: [usePermissionsCampaignAct().canManage]
                }
            },
            {
                path: 'campaign-report-act/campaigns/:campaignActType/:companyId',
                component: () => import('@/pages/AdvertisingCabinet/CampaignReportAct/Campaigns.vue'),
                name: 'campaign-act-reports-company',
                meta: {
                    title: 'Рекламные кампании',
                    isAuth: true,
                    accesses: [usePermissionsCampaignAct().canManage]
                }
            },
            {
                path: 'campaign-report-act/create/:campaignActType/:companyId',
                component: () => import('@/pages/AdvertisingCabinet/CampaignReportAct/ActCreate.vue'),
                name: 'campaign-act-create',
                meta: {
                    title: 'Акт',
                    isAuth: true,
                    accesses: [usePermissionsCampaignAct().canManage]
                }
            },
            {
                path: 'campaign-report-act/update/:actId',
                component: () => import('@/pages/AdvertisingCabinet/CampaignReportAct/ActView.vue'),
                name: 'campaign-act-update',
                meta: {
                    title: 'Акт',
                    isAuth: true,
                    accesses: [usePermissionsCampaignAct().canManage]
                }
            },
            {
                path: 'statistics-adjustments',
                component: () => import('@/pages/StatisticsAdjustments/Main.vue'),
                name: 'statistics-adjustments',
                meta: {
                    title: 'Статистика для корректировок',
                    isAuth: true,
                    accesses: [
                        usePermissionsStatisticsKeywords().canManage,
                        usePermissionsStatisticsCategory().canManage
                    ]
                }
            },
            {
                path: 'sales-report',
                component: () => import('@/pages/AdvertisingCabinet/CampaignSalesReport/Main.vue'),
                name: 'sales-report',
                meta: {
                    title: 'Отчет по продажам',
                    isAuth: true
                }
            }
        ]
    },
    {
        path: '/companies',
        component: () => import('@/pages/Companies/Main.vue'),
        name: 'companies',
        redirect: { name: 'companiesTable' },
        meta: {
            title: 'Поставщики',
            isAuth: true
        },
        children: [
            {
                path: 'tables',
                component: () => import('@/pages/Companies/CompaniesTable/Main.vue'),
                name: 'companiesTable',
                meta: {
                    permissions: [PermissionDictionary.SUPPLIERS]
                }
            },
            {
                path: ':companyId/supplier/:wabcCode',
                component: () => import('@/pages/Companies/SupplierDetail/Main.vue'),
                name: 'supplierDetail',
                meta: {
                    permissions: [PermissionDictionary.SUPPLIERS]
                }
            }
        ]
    },
    {
        path: '/users',
        component: () => import('@/pages/Users/Main.vue'),
        name: 'users',
        redirect: { name: 'usersTable' },
        meta: {
            title: 'Сотрудники компании',
            isAuth: true
        },
        children: [
            {
                path: 'tables',
                component: () => import('@/pages/Users/UsersTable/Main.vue'),
                name: 'usersTable',
                meta: {
                    permissions: [
                        PermissionDictionary.USERS_ADMIN_OWN_COMPANY,
                        PermissionDictionary.USERS_ADMIN_PARTNER_COMPANY
                    ]
                }
            },
            {
                path: ':id?',
                component: () => import('@/pages/Users/UserView/Main.vue'),
                name: 'usersView',
                meta: {
                    permissions: [
                        PermissionDictionary.USERS_ADMIN_OWN_COMPANY,
                        PermissionDictionary.USERS_ADMIN_PARTNER_COMPANY
                    ]
                }
            }
        ]
    },
    {
        path: '/product-promotion',
        component: () => import('@/pages/ProductPromotion/Main.vue'),
        name: 'productPromotion',
        meta: {
            title: 'Продвижение товаров',
            isAuth: true
        }
    },
    {
        path: '/orders',
        component: () => import('@/pages/Orders/Main.vue'),
        redirect: { name: 'orders-table' },
        name: 'orders',
        meta: {
            title: 'Заказы',
            isAuth: true
        },
        children: [
            {
                path: '',
                component: () => import('@/pages/Orders/Table/Main.vue'),
                name: 'orders-table',
                meta: {
                    permissions: [PermissionDictionary.ORDERS_SECTION]
                }
            },
            {
                path: 'supplierCode/:supplierCode/docCda/:docCda/docCdc/:docCdc',
                component: () => import('@/pages/Orders/Detail/Main.vue'),
                name: 'updateOrder',
                meta: {
                    permissions: [PermissionDictionary.ORDERS_SECTION]
                }
            }
        ]
    },
    {
        path: '/stocks',
        component: () => import('@/pages/Stocks/Main.vue'),
        name: 'stocks',
        redirect: { name: 'stocks-table' },
        meta: {
            title: 'Акции',
            isAuth: true
        },
        children: [
            {
                path: 'packages',
                component: () => import('@/pages/Stocks/Package/Main.vue'),
                children: [
                    {
                        path: 'new',
                        name: 'updateStockPackage',
                        component: () => import('@/pages/Stocks/Package/Form/Main.vue'),
                        meta: {
                            accesses: [() => usePermissionsActionPromo().canCreate(useUserStore().companyWabcCode)]
                        }
                    },
                    {
                        path: ':id',
                        name: 'viewStockPackage',
                        component: () => import('@/pages/Stocks/Package/View/Main.vue'),
                        meta: {
                            accesses: [() => usePermissionsActionPromo().canCreate(useUserStore().companyWabcCode)]
                        }
                    }
                ]
            },
            {
                path: 'prices',
                component: () => import('@/pages/Stocks/Price/Main.vue'),
                children: [
                    {
                        path: 'new',
                        name: 'updateSaleInPriceStock',
                        component: () => import('@/pages/Stocks/Price/Form/Main.vue'),
                        meta: {
                            accesses: [() => usePermissionsActionPromo().canCreate(useUserStore().companyWabcCode)]
                        }
                    },
                    {
                        path: ':id',
                        name: 'viewSaleInPriceStock',
                        component: () => import('@/pages/Stocks/Price/View/Main.vue'),
                        meta: {
                            accesses: [() => usePermissionsActionPromo().canCreate(useUserStore().companyWabcCode)]
                        }
                    }
                ]
            },
            {
                path: 'protek-reports',
                name: 'reportStock',
                component: () => import('@/pages/Stocks/Report/Protek/Protek.vue'),
                meta: {
                    permissions: [PermissionDictionary.PROTEK_STOCK_REPORTS]
                }
            },
            {
                path: 'zdravcity-reports',
                name: 'reportStockZdravcity',
                component: () => import('@/pages/Stocks/Report/Zdravcity/Zdravcity.vue'),
                meta: {
                    accesses: [usePermissionsActionPromo().canOpenPage]
                }
            },
            {
                path: 'protek-reports/:id',
                name: 'viewReportStock',
                component: () => import('@/pages/Stocks/Report/View/Main.vue'),
                meta: {
                    permissions: [PermissionDictionary.PROTEK_STOCK_REPORTS]
                }
            },
            {
                path: 'default',
                component: () => import('@/pages/Stocks/Table/Main.vue'),
                name: 'stocks-table',
                meta: {
                    accesses: [() => usePermissionsActionPromo().canRead(useUserStore().companyWabcCode)]
                }
            },
            {
                path: 'promotions',
                component: () => import('@/pages/Stocks/PromotionsCalendar/Main.vue'),
                name: 'promotions-calendar',
                meta: {
                    title: 'Календарь акций',
                    accesses: [usePermissionsPromotion().canRead]
                }
            },
            {
                path: 'promotions/promotion-form/:id?',
                component: () => import('@/pages/Stocks/PromotionForm/Main.vue'),
                name: 'promotion-form',
                meta: {
                    title: 'Форма акции',
                    accesses: [usePermissionsPromotion().canRead]
                }
            },
            {
                path: 'promotions/promotion-request-form/:id?',
                component: () => import('@/pages/Stocks/PromotionRequestForm/Main.vue'),
                name: 'promotion-request-form',
                meta: {
                    title: 'Форма заявки участия в акции',
                    accesses: [usePermissionsPromotion().canRead]
                }
            }
        ]
    },
    {
        path: '/products',
        component: () => import('@/pages/Products/Main.vue'),
        name: 'products',
        meta: {
            title: 'Товары',
            isAuth: true
        },
        children: [
            {
                path: 'default',
                component: () => import('@/pages/Products/Table/Main.vue'),
                name: 'products-table',
                meta: {
                    permissions: [PermissionDictionary.GOODS_SECTION]
                }
            },
            {
                path: 'new',
                component: () => import('@/pages/Products/Create/Main.vue'),
                meta: {
                    permissions: [PermissionDictionary.GOODS_SECTION]
                },
                children: [
                    {
                        path: 'default',
                        component: () => import('@/pages/Products/Create/Form/Main.vue'),
                        name: 'createProductForm',
                        meta: {
                            permissions: [PermissionDictionary.GOODS_SECTION]
                        }
                    },
                    {
                        path: 'upload',
                        component: () => import('@/pages/Products/Create/Upload/Main.vue'),
                        name: 'uploadGoodForm',
                        meta: {
                            permissions: [PermissionDictionary.GOODS_SECTION]
                        }
                    }
                ]
            },
            {
                path: 'categories',
                component: () => import('@/pages/Products/Categories/Main.vue'),
                meta: {
                    title: 'Настройка категорий',
                    isAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/pages/Products/Categories/Table/Main.vue'),
                        name: 'productCategories',
                        meta: {
                            permissions: [PermissionDictionary.SECTION_SETTING_CATEGORIES]
                        }
                    },
                    {
                        path: ':id',
                        component: () => import('@/pages/Products/Categories/Form/Main.vue'),
                        name: 'productCategoryForm',
                        meta: {
                            permissions: [PermissionDictionary.SECTION_SETTING_CATEGORIES]
                        }
                    }
                ]
            },
            {
                path: 'prices',
                component: () => import('@/pages/Products/Prices/Main.vue'),
                meta: {
                    title: 'Настройка категорий',
                    isAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/pages/Products/Prices/Prices.vue'),
                        name: 'productPrices',
                        meta: {
                            permissions: [PermissionDictionary.PRICES]
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@/pages/Products/Prices/PricesCreate.vue'),
                        name: 'createProductPrices',
                        meta: {
                            permissions: [PermissionDictionary.PRICES]
                        }
                    },
                    {
                        path: 'update',
                        component: () => import('@/pages/Products/Prices/PricesUpdate.vue'),
                        name: 'updateProductPrices',
                        meta: {
                            permissions: [PermissionDictionary.PRICES]
                        }
                    }
                ]
            },
            {
                path: ':id',
                component: () => import('@/pages/Products/Detail/Main.vue'),
                name: 'viewProduct',
                meta: {
                    permissions: [PermissionDictionary.GOODS_SECTION]
                }
            }
        ]
    },
    {
        path: '/shipping-adjustments',
        component: () => import('@/pages/ShippingAdjustments/Main.vue'),
        redirect: {
            name: 'plan-suppliers'
        },
        name: 'shipping-adjustments',
        meta: {
            title: 'Корректировки по отгрузке',
            isAuth: true
        },
        children: [
            {
                path: 'plan-suppliers',
                component: () => import('@/pages/ShippingAdjustments/PlanSuppliers/Main.vue'),
                name: 'plan-suppliers',
                meta: {
                    permissions: [PermissionDictionary.SHIPPING_ADJUSTMENTS]
                }
            },
            {
                path: 'range-suppliers',
                component: () => import('@/pages/ShippingAdjustments/RangeSuppliers/Main.vue'),
                name: 'range-suppliers',
                meta: {
                    permissions: [PermissionDictionary.SHIPPING_ADJUSTMENTS]
                }
            },
            {
                path: 'shipping-documents',
                component: () => import('@/pages/ShippingAdjustments/ShippingDocuments/Main.vue'),
                name: 'shipping-documents',
                meta: {
                    permissions: [PermissionDictionary.SHIPPING_ADJUSTMENTS]
                }
            }
        ]
    },
    {
        path: '/plan-reports',
        component: () => import('@/pages/PlanReport/Main.vue'),
        redirect: { name: 'planProtekReport' },
        name: 'plan-reports',
        meta: {
            title: 'Совместное планирование',
            isAuth: true
        },
        children: [
            {
                path: 'protek',
                component: () => import('@/pages/PlanReport/PlanProtekReport/PlanProtekReportPage.vue'),
                name: 'planProtekReport',
                meta: {
                    permissions: [PermissionDictionary.JOINT_PLANNING_PLAN_PROTEK]
                }
            },
            {
                path: 'supplier',
                component: () => import('@/pages/PlanReport/PlanSupplierReport/PlanSupplierReportPage.vue'),
                name: 'planSupplierReport',
                meta: {
                    permissions: [PermissionDictionary.JOINT_PLANNING_VENDOR_PLAN]
                }
            }
        ]
    },
    {
        path: '/forbidden',
        name: '403',
        component: () => import('@/pages/403/ForbiddenPage.vue'),
        meta: {
            title: 'Страница не найдена',
            layout: EmptyLayout
        }
    },
    {
        path: '/downtime',
        name: 'downtime-overlay',
        component: () => import('@/layout/DowntimeOverlay.vue'),
        meta: {
            title: 'Технические работы',
            layout: EmptyLayout
        }
    },
    {
        // See: https://router.vuejs.org/guide/migration/#removed-star-or-catch-all-routes
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('@/pages/404/Main.vue'),
        meta: {
            title: 'Страница не найдена',
            layout: EmptyLayout
        }
    }
];
