import type { CompanyId } from '@/shared/model/types/Company';
import type { UserProfileId } from '@/shared/model/types/UserProfile';
import type { Brand } from '@/shared/model/types/Kernel';

export type UserId = Brand<number, 'user'>;

export enum UserStatus {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    INVITED = 'INVITED',
    BLOCKED = 'BLOCKED',
    PRE_REGISTRATION = 'PRE-REGISTRATION',
    CONFIRMED_EMAIL = 'CONFIRMED-EMAIL',
    UNCONFIRMED = 'UNCONFIRMED'
}

export interface User {
    id: UserId;
    email: string;
    lastActivity: string;
    status: UserStatus;
    createdAt: string;
    updatedAt: string;
}

export interface UserRelationships {
    id: UserId;
    passwordId: number | null;
    companyIds: CompanyId[];
    linkIds: number[];
    notificationIds: number[];
    profileId: UserProfileId | null;
    roleIds: number[];
}
