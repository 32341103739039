import { all as AllRules } from '@vee-validate/rules';
import { dayjs, isValidBarcode, numberFormatDigits } from '@/utils';
import { defineRule } from 'vee-validate';

Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
});

/**
 * Дополнительные правила валидации
 */
defineRule('alpha_latin_dash', (value: string) => {
    const isValid = /^[\w-]*$/.test(value);
    return isValid || 'Может содержать только буквы, цифры и дефис';
});

defineRule('unique_names', (value: unknown, arrayString: string[]) => {
    if (!value && !Array.isArray(arrayString)) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toLowerCase = (item: any) => String(item).toLowerCase();
    const isValid = !arrayString.map(toLowerCase).includes(toLowerCase(value));
    if (!isValid) {
        return 'Данное значение уже указано в списке';
    }
    return true;
});

defineRule('date_format', (date: dayjs.ConfigType, [format]: [format: dayjs.OptionType]) => {
    const isValid = dayjs(date, format).isValid();
    if (!isValid) {
        return 'Не соответствует введенной дате';
    }
    return true;
});

// TODO: специфичное правило, мб лучше определить там где оно используется?
defineRule('imps_limit_max', (value: unknown, [max]: [max: number]) => {
    const currentValue = Number(String(value).replace(/\s/g, ''));
    let isValid = false;

    if (currentValue !== 0) {
        isValid = currentValue <= max;
    }

    if (!isValid) {
        const value = Number(String(max).replace(/\s/g, ''));

        if (value === 0) {
            return `На указанные даты нет возможных показов`;
        }

        return `Доступное количество: ${numberFormatDigits(value)}`;
    }

    return true;
});

defineRule('greater_than_zero', (value: unknown) => {
    const isValid = Number(String(value).replace(/\s/g, '')) > 0;

    if (!isValid) {
        return 'Значение должно быть больше 0';
    }

    return isValid;
});

defineRule('phone', (value: unknown) => {
    if (value) {
        const phone = String(value)?.replace(/\D/g, '');
        return /^(\+?7|8)\d{10}$/g.test(phone) || 'Не соответствует номеру телефона';
    }

    return true;
});

defineRule('correspondent_account', (value: unknown, [bikValue]: [bikValue: string]) => {
    if (!bikValue) {
        return true;
    }
    const isValid = (value as string)?.slice(-3) === bikValue.slice(-3);
    if (!isValid) {
        return 'Последние 3 цифры должны совпадать с БИК';
    }

    return true;
});

defineRule(
    'datepicker_range',
    (date: unknown, [startDate, endDate, format = 'DD.MM.YYYY']: [dayjs.ConfigType, dayjs.ConfigType, string]) => {
        const diff = dayjs(startDate, format).diff(dayjs(endDate, format), 'day');
        const isValid = diff <= 0;

        if (!isValid) {
            return 'Не соответствует интервалу дат';
        }
        return isValid;
    }
);

defineRule(
    'datepicker_range_limit',
    (date: unknown, [startDate, endDate, limit]: [dayjs.ConfigType, dayjs.ConfigType, number]) => {
        const diff = dayjs(endDate, 'DD.MM.YYYY').diff(dayjs(startDate, 'DD.MM.YYYY'), 'day');
        const isValid = diff < limit;

        if (!isValid) {
            return 'Не соответствует интервалу дат';
        }

        return isValid;
    }
);

defineRule(
    'datepicker_current_day',
    (date: dayjs.ConfigType | [dayjs.ConfigType, dayjs.ConfigType], [format = 'DD.MM.YYYY']) => {
        const _date = Array.isArray(date) ? date[1] : date;
        const diff = dayjs(_date, format).diff(dayjs().startOf('day'), 'day');
        const isValid = diff <= 0;
        if (!isValid) {
            return 'Превышает текущий день';
        }
        return isValid;
    }
);

defineRule('array_max', (values: unknown[], [length = 0]) => {
    const isValid = values.length <= length;
    if (!isValid) {
        return `Список не должен быть больше ${length} позиций`;
    }
    return true;
});

defineRule('array_min', (values: unknown[], [length = 0]) => {
    const isValid = values.length >= length;
    if (!isValid) {
        return `Список должен быть больше или равен ${length} позиций`;
    }
    return true;
});

defineRule('url', (value: string) => {
    const patternURL = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.:~+]*)*' + // port and path
            '(\\?[;&a-zа-яё\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    );

    const patternDeepLink = /^ru\.zdravcity:\/\/\S*$/;

    const isValid = patternURL.test(value) || patternDeepLink.test(value);

    if (!isValid) {
        return 'Не соответствует url адресу';
    }

    return true;
});

// SEE: https://github.com/Kholenkov/js-data-validation/blob/master/data-validation.js#L23
defineRule('inn', (inn?: string | number) => {
    let result = false;
    if (typeof inn === 'number') {
        inn = inn.toString();
    } else if (typeof inn !== 'string') {
        inn = '';
    }
    if (!inn.length) {
        return 'ИНН пуст';
    } else if (/\D/.test(inn)) {
        return 'ИНН может состоять только из цифр';
    } else if (![10, 12].includes(inn.length)) {
        return 'ИНН может состоять только из 10 или 12 цифр';
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const checkDigit = (inn: any, coefficients: any) => {
        let n = 0;
        for (const i in coefficients) {
            n += coefficients[i] * inn[i];
        }
        return (n % 11) % 10;
    };
    switch (inn.length) {
        case 10: {
            const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if (n10 === parseInt(inn[9])) {
                result = true;
            }
            break;
        }
        case 12: {
            const n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            const n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
                result = true;
            }
            break;
        }
    }
    if (!result) {
        return 'Неправильное контрольное число';
    }

    return true;
});

defineRule('swift', (swift?: number | string) => {
    if (typeof swift === 'number') {
        swift = swift.toString();
    } else if (typeof swift !== 'string') {
        swift = '';
    }
    if (!swift.length) {
        return 'SWIFT пуст';
    } else if (!/[a-zA-Z\d]/.test(swift)) {
        return 'SWIFT может состоять только из цифр и букв английского алфавита';
    } else if (swift.length < 8 || swift.length > 11) {
        return 'SWIFT может состоять от 8 до 11 символов';
    }

    return true;
});

defineRule('max_number', (value: unknown, [max]: [number]) => {
    const currentValue = Number(String(value).replace(/\s/g, ''));

    if (Number.isNaN(currentValue)) {
        return true;
    }

    const isValid = currentValue <= max;

    if (!isValid) {
        return `Максимальное значение ${max}`;
    }

    return true;
});

defineRule('array_required', (values: unknown[]) => {
    const isValid = values.length > 0;
    if (!isValid) {
        return `Список не может быть пустым`;
    }

    return true;
});

defineRule('barcode', (barcode: number | string | undefined) => {
    if (typeof barcode === 'number') {
        barcode = barcode.toString();
    } else if (typeof barcode !== 'string') {
        barcode = '';
    }

    if (!barcode.length) {
        return true;
    }

    // SEE: https://www.barcodefaq.com/barcode-match/
    const EAN8_LENGTH = 8;
    const EAN13_LENGTH = 13;
    if (!/[\d+]/.test(barcode)) {
        return 'Штрихкод может состоять только из цифр';
    }

    if (![EAN8_LENGTH, EAN13_LENGTH].includes(barcode.length)) {
        return 'Штрихкод может быть длинной 8 или 13 символов';
    }

    // SEE: https://barcode-coder.com/en/ean-8-specification-101.html
    const isValidBarcode = (value: string): boolean => {
        const paddedValue = value.padStart(13, '0');

        let oddSum = 0;
        let evenSum = 0;
        for (let i = 0; i < paddedValue.length - 1; i++) {
            const digit = Number(paddedValue[i]);
            if (i % 2 === 0) {
                evenSum += digit;
            } else {
                oddSum += digit;
            }
        }

        const checksum = (10 - ((3 * oddSum + evenSum) % 10)) % 10;
        const expectedChecksum = Number(paddedValue[paddedValue.length - 1]);

        return checksum === expectedChecksum;
    };

    return isValidBarcode(barcode) || 'Не соответствует EAN8 или EAN13';
});

/*
Правила что нужно добавить
    if (rule === 'double') {
        extend(rule, {
            ...rules[rule],
            message: 'Должно быть допустимым десятичным числом'
        });
    }
 */

defineRule('alpha_space_dot_symbol', (value: string) => {
    return /^[a-zA-Zа-яёА-ЯË\s.]*$/g.test(value) || 'Должен содержать только символы';
});

defineRule('barcode_comma', (barcode: string) => {
    barcode = String(barcode);
    const isLastSymbolComma = barcode.at(-1) === ',';

    if (isLastSymbolComma) {
        return 'Укажите следующий штрих-код без пробела';
    }

    const hasComma = barcode.includes(',');

    if (hasComma) {
        return barcode.split(',').map(isValidBarcode).every(Boolean);
    }

    return isValidBarcode(barcode) || 'Не соответствует EAN8 или EAN13';
});

defineRule(
    'datepicker_after_day',
    (
        date: dayjs.ConfigType | [dayjs.ConfigType, dayjs.ConfigType],
        [format, compareDate, message, checkIndex = '1']: [string, dayjs.ConfigType, string | undefined, string]
    ) => {
        const _date = Array.isArray(date) ? date[Number(checkIndex)] : date;
        if (!_date) {
            return true;
        }
        const isValid = dayjs(_date, format).isAfter(dayjs(compareDate, format).subtract(1, 'day'), 'day');
        if (!isValid) {
            return message || 'Выберите день позже';
        }
        return isValid;
    }
);

defineRule(
    'datepicker_before_day',
    (
        date: dayjs.ConfigType | [dayjs.ConfigType, dayjs.ConfigType],
        [format, compareDate, message, checkIndex = '1']: [string, dayjs.ConfigType, string | undefined, string]
    ) => {
        const _date = Array.isArray(date) ? date[Number(checkIndex)] : date;
        if (!_date) {
            return true;
        }
        const isValid = dayjs(_date, format).isBefore(dayjs(compareDate, format).subtract(1, 'day'), 'day');
        if (!isValid) {
            return message || 'Выберите день раньше';
        }
        return isValid;
    }
);

defineRule('float', (value: unknown) => {
    const currentValue = Number(String(value).trim());

    if (Number.isNaN(currentValue)) {
        return `Введите число`;
    }

    return true;
});

defineRule('array_every', (values: unknown[], [excludedResolver]: [(val: unknown) => boolean]) => {
    const isExcluded = values?.every(excludedResolver);

    if (!isExcluded) {
        return `Значение не соответствует указанным параметрам"`;
    }

    return true;
});
