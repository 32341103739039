/**
 * Обновление данных профиля
 */
import type { UserProfile, UserProfileId } from '@/shared/model/types/UserProfile';
import client from '@/shared/api/client';
import * as JsonApiDefaultMapper from '@/shared/api/mapper/JsonApiDefaultMapper';

export async function updateUserProfile(profileId: UserProfileId, profile: Partial<UserProfile>) {
    await client.patch(`/api/users-profiles/${profileId}`, profile);
}

export async function createUserProfile(payload: Partial<UserProfile>): Promise<{ userProfile: UserProfile }> {
    const response = await client.post(`/api/users-profiles`, payload);
    return {
        userProfile: JsonApiDefaultMapper.toClient<UserProfile>(response.data.data)
    };
}
