// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isObjectLike = (val: any) => val !== null && typeof val === 'object';

/**
 * Преобразовать примитивное значение строки в number
 * @param value
 * @returns {number|number}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertStringToNumber(value: any) {
    return typeof value !== 'number' ? +value?.replace(/\s/g, '') : value;
}

/**
 * Преобразовать number значение в строку, разделенную пробелом через каждый 3 символа
 * @param value
 * @returns {string}
 * @example numberFormatDigits(1000) => "10 000"
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function numberFormatDigits(value: any) {
    if (typeof value !== 'number') return value;
    const maximumFractionDigits = value < 10 ? 1 : 0;
    return new Intl.NumberFormat('ru-RU', { maximumFractionDigits }).format(value);
}

/**
 * Преобразовать number значение в строку,
 * разделенную пробелом через каждый 3 символа,
 * с дробной частью до сотых. С возможностью отображения валюты RUB
 * @param value
 * @param {boolean} symbol
 * @returns {string}
 * @example numberFormatCurrency(1000) => "10 000,00"
 * @example numberFormatCurrency(1000, true) => "10 000,00 ₽"
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function numberFormatCurrency(value: any, symbol = false) {
    if (value === undefined || Number.isNaN(value)) {
        return '';
    }

    const currency = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        currencyDisplay: 'symbol'
    }).format(value);

    return currency.substring(0, currency.length - (symbol ? 0 : 2));
}

/**
 * Перевести выбранные поля плоской структуры объекта в null
 * @param {object} object
 * @param {string} valueMatch
 * @returns {{}}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertObjectValueToNull(object: any, valueMatch = '') {
    if (object && !isObjectLike(object)) return object;
    return Object.entries(object).reduce((acc, [k, v]) => ({ ...acc, [k]: v === valueMatch ? null : v }), {});
}

/**
 * Преобразовать строку в нижний регистр, с отсечением пустых символов
 * @return string
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertStringToLowerCase(value: any) {
    // prettier-ignore
    return String(value)?.toString().trim().toLowerCase() || '';
}

/**
 * Множественное значение
 * @example pluralize(['год','года','лет'], 'Меньше года')(0) => "Меньше года"
 * @example pluralize(['год','года','лет'])(4) => "4 года"
 *
 * @param {array} titles
 * @param {string} empty
 * @returns {Function}
 */
export function pluralize(titles: string[] = [], empty = '') {
    if (!(titles && Array.isArray(titles) && titles.length)) {
        return '';
    }

    const cases = [2, 0, 1, 1, 1, 2];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (count: any) => {
        count = Math.abs(count); // для отрицательных чисел
        if (count === 0 && empty) return empty;
        return titles[count % 100 > 4 && count % 100 < 20 ? 2 : cases[count % 10 < 5 ? count % 10 : 5]];
    };
}

export const positionPluralize = pluralize(['позиция', 'позиции', 'позиций']);
export const selectedPluralize = pluralize(['Выбрана', 'Выбрано', 'Выбрано']);

/**
 * Конвертация данных для проксированных запросов к OEBs
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertToOEBsFormat(value: any) {
    return value ? [value] : null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertToOEBs(value: any) {
    return value.length === 0 ? null : value;
}

/**
 * Конвертация данных для проксированных запросов к OEBs
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function strToNumberArray(value: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return value.split`,`.map((x: any) => +x);
}
/**
 * Конвертация данных для проксированных запросов к OEBs
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function strToArray(value: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return value.split`,`.map((x: any) => x);
}
