import { ability, subject } from '@/shared/lib/casl/casl';
import type { CompanyProtekId } from '@/shared/model/types/Company';

export const usePermissions = () => {
    const { can } = ability;

    const canManage = () => can('manage', 'B2BAsyncReportTask');

    const canAdGoodsReport = (supplierCode?: CompanyProtekId | null) => {
        return can(
            'create',
            subject('ReportAdGoods', {
                taskType: 'adGoods',
                ...(supplierCode ? { supplier_code: supplierCode } : undefined)
            })
        );
    };

    return {
        canManage,
        canAdGoodsReport
    };
};
