import type { AxiosResponse } from 'axios';
import { NetworkError } from '@/errors/NetworkError';

const HUMAN_FRIENDLY_ERROR_TYPES = new Map<string, string>([
    [
        'advertising:media-scout:receive-creative-token:company-not-found',
        'Не получилось создать ОРД токен. Поставщик не найден'
    ],
    [
        'advertising:media-scout:receive-creative-token:company-has-no-inn',
        'Не получилось создать ОРД токен. У поставщика не указан ИНН'
    ],
    [
        'advertising:media-scout:receive-creative-token:company-has-no-type',
        'Не получилось создать ОРД токен. У поставщика не указан тип компании'
    ],
    [
        'advertising:media-scout:receive-creative-token:incorrect-url',
        'Не получилось создать ОРД токен. Некорректный URL'
    ],
    [
        'advertising:media-scout:receive-creative-token:no-banner-url',
        'Не получилось создать ОРД токен. Не указана ссылка на баннер'
    ],
    [
        'advertising:media-scout:receive-creative-token:unsupported-creative-form',
        'Не получилось создать ОРД токен. Не поддерживаемая creative form'
    ],
    [
        'advertising:media-scout:receive-creative-token:no-zdravcity-product-guid',
        'Не получилось создать ОРД токен. Не указан GUID товара с сайта здравсити'
    ],
    [
        'advertising:media-scout:receive-creative-token:more-than-one-client',
        'Не получилось создать ОРД токен. Есть больше одного клиента'
    ],
    [
        'advertising:media-scout:receive-creative-token:more-than-one-contract',
        'Не получилось создать ОРД токен. У поставщика может быть только один контракт на оказание рекламных услуг'
    ],
    ['advertising:media-scout:receive-creative-token:unknown', 'Не получилось создать ОРД токен. Неизвестная ошибка'],
    [
        'advertising:media-scout:receive-creative-token:zdravcity-product-not-found',
        'Не получилось создать ОРД токен. Товар не найден на сайте здравсити'
    ],
    [
        'advertising:media-scout:receive-creative-token:zdravcity-product-has-no-picture',
        'Не получилось создать ОРД токен. У товара нет фото'
    ],
    [
        'advertising:media-scout:receive-creative-token:zdravcity-product-incorrect-picture-url',
        'Не получилось создать ОРД токен. У товара указана не правильная ссылка на фото'
    ],
    [
        'advertising-campaigns:receive-token:incorrect-placement',
        'Параметры размещения не соответствуют ни одному из вариантов конфигурации с поддержкой токена ОРД'
    ],
    [
        'advertising:media-scout:receive-creative-token:company-has-no-ad-agreement',
        'У компании поставщика отсутствует рекламный договор'
    ],
    [
        'advertising:media-scout:receive-creative-token:ad-agreement-expired',
        'Договор неактуален или срок действия договора истек'
    ],
    ['DISCONT_LESS_VALUE', 'Ставка в рублях за показы указана больше чем задано в рекламном размещении'],
    ['CREATE_KEYWORD_STATISTICS_ERROR', 'Данное ключевое слово уже существует/было создано'],
    ['UPDATE_KEYWORD_STATISTICS_ERROR', 'Данное ключевое слово уже существует/было создано'],
    ['CATEGORY_STATISTICS_UPSERT_MANY_ERROR', 'Указано некорректное значение параметров категории'],
    ['CATEGORY_STATISTICS_UPDATE_ERROR', 'Ошибка при обновлении значений параметров категории'],
    [
        'END_DATE_MUST_BE_GREATER_OR_EQUAL_CURRENT_DATE',
        'Дата окончания периода должна быть больше или равна текущей дате'
    ]
]);

const HUMAN_FRIENDLY_SERVER_ERROR_TYPES = new Set(['TEMPLATE_IS_NOT_SUITABLE_FOR_THIS_CATEGORY']);

export const isHumanFriendlyErrorType = (errorType: string) => {
    return HUMAN_FRIENDLY_ERROR_TYPES.has(errorType) || HUMAN_FRIENDLY_SERVER_ERROR_TYPES.has(errorType);
};

export class HumanFriendlyError extends NetworkError {
    readonly name = 'HumanFriendlyError';

    constructor(
        readonly response: AxiosResponse,
        readonly type: string
    ) {
        let message = HUMAN_FRIENDLY_ERROR_TYPES.get(type);
        if (HUMAN_FRIENDLY_SERVER_ERROR_TYPES.has(type)) {
            message = response?.data?.message;
        }
        if (!message) {
            throw new NetworkError(`Нельзя сгенерировать HumanFriendlyError. Неизвестный тип ${type}`, response);
        }
        super(message, response);
    }
}
