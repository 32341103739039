import { type RemovableRef, useStorage } from '@vueuse/core';

export class LocalStorageService<T = string> {
    private storage: RemovableRef<T>;

    constructor(private readonly key: string) {
        this.storage = useStorage<T>(this.key, null, localStorage);
    }

    public set(value: T): void {
        this.storage.value = value;
    }

    public get(): T | null {
        return this.storage.value;
    }

    public remove(): void {
        this.storage.value = null;
    }
}
