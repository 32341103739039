export default {
    code: 'ru',
    messages: {
        alpha: 'Поле может содержать только буквы',
        alpha_dash: 'Поле может содержать только буквы, цифры и дефис',
        alpha_num: 'Поле может содержать только буквы и цифры',
        alpha_spaces: 'Должен содержать только символы и пробелы',
        between: 'Должен содержать промежуток от 0:{min} до 1:{max}',
        confirmed: 'Значения не совпадают',
        digits: 'Поле должно быть числовым и точно содержать 0:{length} цифры',
        dimensions: 'Поле должно быть 0:{width} пикселей на 1:{height} пикселей',
        email: 'Не соответствует email адресу',
        not_one_of: 'Поле должно быть допустимым значением',
        ext: 'Поле должно быть действительным файлом. ({args})',
        image: 'Поле должно быть изображением',
        one_of: 'Должно быть допустимым значением',
        integer: 'Поле должно быть целым числом',
        length: 'Длина поля должна быть 0:{length}',
        max: 'Должно быть не более 0:{length} символов',
        max_value: 'Значение должно быть не больше 0:{max}',
        mimes: 'Поле должно иметь допустимый тип файла. ({args})',
        min: 'Должно быть не менее 0:{length} символов',
        min_value: 'Поле должно быть 0:{min} или больше',
        numeric: 'Должен содержать только числа',
        regex: 'Поле имеет ошибочный формат',
        required: 'Обязательно для заполнения',
        required_if: 'Обязательно для заполнения',
        size: 'Поле должно быть меньше, чем 0:{size}KB'
    }
};
