import { createRouter, createWebHistory } from 'vue-router';
import { routes } from './routes';
import { PermissionDictionary } from '@/shared/model/types/Permission';

declare module 'vue-router' {
    interface RouteMeta {
        title?: string;
        isAuth?: boolean;
        permissions?: PermissionDictionary[];
        accesses?: (() => Promise<boolean> | boolean)[];
    }
}

export const router = createRouter({
    routes,
    linkActiveClass: 'active',
    history: createWebHistory()
    /**
     * При изменении данных в query-параметрах страница пролистывается в самый вверх
     * Возможно, стоит пока отключить этот параметр для дальнейшего тестирования
     */
    // scrollBehavior() {
    //     return { x: 0, y: 0 };
    // }
});
