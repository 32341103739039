import { dayjs } from '@/utils';
import type { Dayjs } from 'dayjs';
import type { PeriodType } from './types';

const currentDate = dayjs();

// prettier-ignore
const periodMap = new Map<PeriodType, [Dayjs, Dayjs] | []>([
    ['lastWeek', [ currentDate.subtract(1, 'week').startOf('week'), currentDate ]],
    ['thisWeek', [ currentDate.subtract(0, 'week').startOf('week'), currentDate ]],
    ['lastMonth', [ currentDate.subtract(1, 'month').startOf('month'), currentDate.subtract(1, 'month').endOf('month') ]],
    ['thisMonth', [ currentDate.subtract(0, 'month').startOf('month'), currentDate ]],
    ['YTD', [ currentDate.startOf('year'), currentDate ]],
    ['MAT', [ currentDate.subtract(1, 'year'), currentDate]],
    ['range', []],
])

/**
 * Справочник заготовленных периодов
 * {PeriodType} periodType
 */
export const getPeriodValues = (periodType: PeriodType, formatMask?: string): [string, string] | [] => {
    const periods = periodMap.get(periodType);

    if (periods?.length) {
        return periods.map(day => day.format(formatMask)) as [string, string] | [];
    }

    return [];
};
