<template>
    <div class="multi-file-upload" :class="classes" @drop="drop" @dragover="dragover" @dragleave="dragleave">
        <label class="file-upload">
            <input
                :id="id"
                ref="fileInputEl"
                type="file"
                class="input-file"
                :accept="accept"
                multiple
                :disabled="disabled"
                data-testid="multi-file-input"
                @change="changeFiles"
            />
            <span class="icon-wrapper">
                <PIcon class="icon" name="cloud-up" size="32" />
            </span>
            <span class="content">
                <strong class="title">{{ label }}</strong>
                <span class="file-types">{{ types }}</span>
            </span>
        </label>

        <div class="files">
            <slot name="queue" :files="list">
                <div v-for="({ status, error, file }, i) in list" :key="`queue${i}`" class="file">
                    <PIcon class="icon" :class="`--${status}`" :name="getIcon(status)" />
                    <span class="file-text">
                        <span class="file-name">{{ file.name }}</span>
                        <span class="file-error">{{ error }}</span>
                    </span>
                    <PButton v-if="!disabled" icon="trash-full" variant="text" @click="deleteFile(file)"
                        >Удалить</PButton
                    >
                </div>
            </slot>

            <slot name="files" :files="files">
                <template v-if="previewType === PreviewType.DEFAULT">
                    <div v-for="(file, i) in files" :key="`file${i}`" class="file">
                        <PIcon class="icon --uploaded" name="done-all" />
                        <span class="file-text">
                            <slot name="file-name" :file="file">
                                <FilePreview :url="file.url" class="file-name" :data-testid="`file-name-${i}`" />
                            </slot>
                        </span>

                        <PButton
                            v-if="!disabled"
                            icon="trash-full"
                            variant="text"
                            class="file-remove"
                            :data-testid="`url-delete-${i}`"
                            @click="remove(file)"
                        >
                            Удалить
                        </PButton>
                    </div>
                </template>

                <div v-if="files.length > 0 && previewType === PreviewType.GALLERY" class="gallery">
                    <div v-for="(file, i) in files" :key="`file${i}`" class="gallery_item">
                        <img class="gallery_img" :src="file.url" :alt="file.url" />
                        <PButton
                            v-if="!disabled"
                            icon="trash-full"
                            variant="text"
                            class="gallery_remove"
                            :data-testid="`url-delete-${i}`"
                            @click="remove(file)"
                        />
                    </div>
                </div>
            </slot>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import PIcon from '../../PIcon/PIcon.vue';
import PButton from '../../PButton/PButton.vue';
import FilePreview from '../../FilePreview/FilePreview.vue';
import { bytesToSize, generateUUID } from '@/utils';
import { notifyError } from '@/shared/model/utils/showNotify';
import { axios } from '@/api/axios';
import type { IconName } from '@/shared/model/types/Icons';
import type { PropExtensions } from '@/shared/ui/FileUpload/model/types';
import { validateExtension, validateSize, validationDimensions } from '@/shared/ui/FileUpload/model/validator';
import { useDnDFiles } from '@/shared/ui/FileUpload/model/compositions/dndFiles';

type MFUFile = Record<string, unknown> & { url: string; name: string };

enum PreviewType {
    DEFAULT = 'default',
    GALLERY = 'gallery'
}

enum LoadStatus {
    PENDING = 'pending',
    LOADING = 'loading',
    UPLOADED = 'uploaded',
    ERROR = 'error'
}

interface Props {
    id?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modelValue?: any[];
    label?: string | number;
    types?: string;
    disabled?: boolean;
    accept?: string;
    postAction?: string;
    extensions?: PropExtensions;
    size?: number;
    width?: number;
    height?: number;
    validationDimensionsType?: 'equal' | 'within';
    getUrl?: (response: unknown) => string;
    previewType?: PreviewType;
    payload?: object;
}
const props = withDefaults(defineProps<Props>(), {
    id: generateUUID(),
    modelValue: () => [],
    label: 'Загрузите или перетащите файл',
    types: 'В любом формате',
    accept: '*/*',
    postAction: '/api/files/upload?common=true',
    extensions: '',
    size: 3.2e7, // 32мб
    width: undefined,
    height: undefined,
    getUrl: undefined,
    payload: undefined,
    disabled: false,
    validationDimensionsType: 'equal',
    previewType: PreviewType.DEFAULT
});

const emit = defineEmits<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'update:modelValue', val: any): void;
    (e: 'remove', val: MFUFile): void;
    (e: 'add', val: MFUFile): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'upload', val: any): void;
}>();

const list = ref<{ file: File; status: LoadStatus; error?: string; url?: string }[]>([]);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const files = computed<any[]>({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emit('update:modelValue', newValue);
    }
});
watch<MFUFile[]>(
    files,
    newVal => {
        if (props.getUrl) {
            const urls = new Set(newVal.map(f => f.url));
            // @ts-expect-error FIXME
            list.value = list.value.filter(item => !urls.has(item.url));
        }
    },
    {
        deep: true
    }
);
const fileInputEl = ref<HTMLInputElement | null>(null);

const upload = async (file: File): Promise<unknown> => {
    const formData = new FormData();
    formData.append('file', file);

    if (props.payload) {
        Object.entries(props.payload).forEach(([key, value]) => {
            formData.append(key, value);
        });
    }

    const response = await axios.post(props.postAction, formData);

    emit('upload', response.data);
    if (fileInputEl.value) {
        fileInputEl.value.value = '';
    }
    return response;
};

const queue = () => {
    const files = new Map<File, { status: LoadStatus; error?: string; url?: string }>();
    const updateList = () => {
        list.value = Array.from(files).map(([file, info]) => {
            return {
                ...info,
                file
            };
        });
    };

    let isRun: boolean;

    const run = async () => {
        if (isRun) {
            return;
        }
        isRun = true;
        for (const [file] of files) {
            const NAME_MAX_LENGTH = 10;
            const shortName =
                file.name.substring(0, NAME_MAX_LENGTH) + (file.name.length > NAME_MAX_LENGTH ? '...' : '');
            try {
                // @ts-expect-error FIXME
                files.get(file).status = LoadStatus.LOADING;
                // @ts-expect-error FIXME
                files.get(file).error = '';
                updateList();
                const response: unknown = await upload(file);
                if (props.getUrl) {
                    // @ts-expect-error FIXME
                    files.get(file).url = props.getUrl(response);
                }
                // @ts-expect-error FIXME
                files.get(file).status = LoadStatus.UPLOADED;
            } catch (error) {
                if (files.has(file)) {
                    const errorMessage = error instanceof Error ? error.message : 'Неизвестная ошибка';
                    // @ts-expect-error FIXME
                    files.get(file).status = LoadStatus.ERROR;
                    // @ts-expect-error FIXME
                    files.get(file).error = errorMessage;
                    notifyError(`'Файл (${shortName}) не соответствует техническим требованиям. ${errorMessage}`);
                }
            } finally {
                files.delete(file);
                updateList();
            }
        }
        isRun = false;
    };

    return {
        async add(file: File): Promise<void> {
            if (props.disabled) {
                return;
            }

            if (!(await validationDimensions(file, props))) {
                notifyError(`Файл ${file.name} не соответствует размерам ${props.width}×${props.height}`);
                return;
            }

            if (!validateExtension(file.name, props)) {
                notifyError(`Неправильное разрешение. ${props.types || props.extensions}`);
                return;
            }

            if (!validateSize(file.size, props)) {
                notifyError(`Файл превышает допустимый размер в ${bytesToSize(props.size)}`);
                return;
            }

            files.set(file, {
                status: LoadStatus.PENDING
            });
            updateList();

            run();
        },
        delete(file: File): void {
            files.delete(file);
            updateList();
        }
    };
};

const { add: addFile, delete: deleteFile } = queue();

const { isDropActive, drop, dragover, dragleave } = useDnDFiles({
    addFile
});

const changeFiles = (event: Event): void => {
    // @ts-expect-error FIXME
    for (const file of (event.target as HTMLInputElement).files) {
        addFile(file);
    }
};

const classes = computed<Record<string, boolean>>(() => {
    return {
        '--active': isDropActive.value || list.value.length > 0 || props.modelValue.length > 0,
        '--drop-active': isDropActive.value,
        disabled: props.disabled
    };
});

const getIcon = (status: LoadStatus): IconName => {
    switch (status) {
        case LoadStatus.PENDING:
            return 'waiting';
        case LoadStatus.ERROR:
            return 'warning';
        case LoadStatus.LOADING:
            return 'done';
        case LoadStatus.UPLOADED:
        default:
            return 'done-all';
    }
};

const remove = (file: MFUFile) => {
    emit('remove', file);
    files.value = files.value.filter(f => !Object.is(f, file));
};
</script>

<style lang="scss" scoped>
.multi-file-upload {
    position: relative;
    display: block;
    min-height: 69px;
    border: 1px dashed #b9c1cc;
    border-radius: 8px;
    padding: 14px 16px;
    width: 100%;
    z-index: 1;
    background-color: white;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    &.--active {
        border-color: #b9c1cc;
        background-color: #f5f7fa;
        cursor: default;
    }

    &.--drop-active {
        border-color: blue;
        background-color: #f5f7fa;
    }

    .--uploaded {
        color: var(--success);
    }
}

.file-upload {
    position: relative;
    display: grid;
    grid-template-columns: 45px auto;
    align-items: start;
    grid-gap: 1rem;
}

.input-file {
    position: absolute;
    inset: 0;
    opacity: 0;
    cursor: pointer;

    &[disabled] {
        cursor: default;
    }
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    padding: 4px 2px;
    border: 1px solid var(--border-light);
    background-color: white;
    border-radius: 8px;
    color: var(--text-color-2);
}

.content {
    min-width: 0;
}

.title {
    display: block;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #1a1e32;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.file-types {
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: #8f969e;
}

.files {
    margin-top: 16px;
    text-align: left;

    &:empty {
        display: none;
    }
}

.file {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 6px 8px;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
}

.file-text {
    display: block;
    width: 100%;
    overflow: hidden;
}

.file-name {
    text-overflow: ellipsis;
    white-space: pre;
    width: 100%;
    display: block;
    overflow: hidden;
}

.file-error {
    color: var(--danger);
}

.gallery {
    display: flex;
    flex-wrap: wrap;

    &_item {
        position: relative;
        width: 120px;
        height: 84px;
        margin-right: 8px;
        margin-bottom: 8px;
        border-radius: 4px;
        overflow: hidden;
    }

    &_img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    &_remove {
        position: absolute;
        top: 8px;
        right: 8px;
    }
}
</style>
