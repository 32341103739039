import { generateUUID } from '@/utils';
import { axios } from '@/api/axios';
import type { JsonRpcPayload, JsonRpcResponse } from '@/models/JsonRpcPayload';

/**
 * Выбор активной компании для пользователя
 */
export async function setActiveCompany(userId: number, companyId: number) {
    const payload: JsonRpcPayload<{ userId: number; companyId: number }> = {
        jsonrpc: '2.0',
        method: 'users.setActiveCompany',
        id: generateUUID(),
        params: {
            userId,
            companyId
        }
    };
    const { data } = await axios.post<JsonRpcResponse<{ companyId: number; accessToken: string }>>('/rpc', payload);
    return data.result;
}
