<template>
    <div class="notification-container">
        <transition-group class="notification-list" name="fade" tag="div" @enter="enter" @leave="leave">
            <div v-for="item in items" :key="item.id" class="notification-wrapper">
                <component :is="item.component" v-bind="item.props" @close="() => remove(item.id)" />
            </div>
        </transition-group>
    </div>
</template>

<script lang="ts" setup>
import { useNotificationStore } from '../model/useNotifyStore';

const { items, remove } = useNotificationStore();
const enter = (element: Element): void => {
    requestAnimationFrame(() => {
        (element as HTMLElement).style.setProperty('--height', `${element.scrollHeight + 10}px`);
    });
};

const leave = (element: Element): void => {
    requestAnimationFrame(() => {
        (element as HTMLElement).style.setProperty('--height', '0');
        (element as HTMLElement).style.setProperty('overflow', 'hidden');
    });
};
</script>

<style lang="scss" scoped>
.notification-container {
    position: fixed;
    z-index: var(--z-index-notification);
    right: 0;
    top: calc(var(--header-height) + 20px);
}

.notification-list {
    --height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-items: right;
}

.notification-title {
    font-weight: 600;
}

.notification-wrapper {
    height: var(--height);
    padding-bottom: 2rem;
    align-self: flex-end;
}

.fade-enter-active,
.fade-leave-active,
.fade-move {
    transition: all 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    padding: 0;
    transform: translateX(100%);
}
</style>
