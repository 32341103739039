/**
 * Преобразует число байтов в human-readable формат.
 * @param num
 * @param precision
 * @param addSpace
 * @returns {string|*}
 * @example bytesToSize(1000); // '1KB'
 * @example bytesToSize(1000000); // '1MB'
 */
export function bytesToSize(num = 0, precision = 3, addSpace = false) {
    const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    if (Math.abs(num) < 1) return num + (addSpace ? ' ' : '') + UNITS[0];
    const exponent = Math.min(Math.floor(Math.log10(num < 0 ? -num : num) / 3), UNITS.length - 1);
    const n = Number(((num < 0 ? -num : num) / 1000 ** exponent).toPrecision(precision));
    return (num < 0 ? '-' : '') + n + (addSpace ? ' ' : '') + UNITS[exponent];
}
