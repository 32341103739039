export const DATE_ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';
export const DATE_ISO_FORMAT_WITHOUT_TZ = 'YYYY-MM-DDTHH:mm:ss.000';

export const ICONS = [
    '123',
    'active',
    'add-manufacture',
    'add-to-queue',
    'add-users',
    'addition',
    'airplay',
    'alarm',
    'alarm-add',
    'alert',
    'analysis',
    'assortment',
    'attach',
    'audience',
    'bar-chart',
    'bar-chart-alt',
    'bar-chart-horizontal',
    'bar-chart-square',
    'bestseller',
    'block',
    'bulb',
    'calc',
    'caret-down',
    'caret-left',
    'caret-right',
    'caret-up',
    'cart-filled',
    'cart-outline',
    'cast',
    'categories',
    'checkbox',
    'checkbox-checked',
    'checkbox-square',
    'chevron-big-down',
    'chevron-big-left',
    'chevron-big-right',
    'chevron-big-up',
    'chevron-down',
    'chevron-duo-down',
    'chevron-duo-left',
    'chevron-duo-right',
    'chevron-duo-up',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'chevrondown',
    'chevronleft',
    'chevronright',
    'chevronup',
    'circle-check',
    'circle-check-outline',
    'circle-checked',
    'circle-chevron-down',
    'circle-chevron-left',
    'circle-chevron-right',
    'circle-chevron-up',
    'circle-down',
    'circle-left',
    'circle-right',
    'circle-up',
    'city',
    'clients',
    'clock',
    'close',
    'close-big',
    'close-small',
    'cloud',
    'cloud-check',
    'cloud-close',
    'cloud-down',
    'cloud-off',
    'cloud-outline',
    'cloud-up',
    'cokie',
    'color',
    'command',
    'comment',
    'comment-check',
    'comment-minus',
    'comment-plus',
    'confused',
    'conversion-one',
    'conversion-two',
    'copy',
    'credit-card',
    'credit-card-alt',
    'cVP',
    'dashboard',
    'dashboard-02',
    'date',
    'detail-sales',
    'done',
    'done-all',
    'dots',
    'doughnut-chart',
    'down-sales',
    'download',
    'download-done',
    'edit',
    'email-report',
    'error',
    'error-outline',
    'exit',
    'expand',
    'external-link',
    'eye',
    'eye-visible',
    'fast-forward',
    'fast-rewind',
    'file-archive',
    'file-blank-fill',
    'file-blank-outline',
    'file-css',
    'file-download-pdf',
    'file-find',
    'file-html',
    'file-image',
    'file-iso',
    'file-jpg',
    'file-js',
    'file-minus',
    'file-new',
    'file-pdf',
    'file-png',
    'file-svg',
    'file-xls',
    'filter-price-one',
    'filter-price-two',
    'first-page',
    'flag-fill',
    'flag-outline',
    'folder',
    'folder-minus',
    'folder-open',
    'folder-plus',
    'format',
    'global',
    'grid',
    'grid-big',
    'grid-big-round',
    'grid-horizontal',
    'grid-horizontal-round',
    'grid-round',
    'grid-small',
    'grid-small-round',
    'grid-vertical',
    'grid-vertical-round',
    'hamburger',
    'happy',
    'heart-fill',
    'heart-outline',
    'help-circle',
    'help-circle-outline',
    'help-questionmark',
    'hide',
    'image-alt',
    'info',
    'info-circle',
    'info-circle-outline',
    'info-square',
    'info-square-outline',
    'inside',
    'label',
    'last-page',
    'layers',
    'layers-alt',
    'lenta',
    'line-chart-down',
    'line-chart-up',
    'link',
    'link-02',
    'list-check',
    'list-minus',
    'list-ol',
    'list-plus',
    'list-ul',
    'loading',
    'location',
    'location-outline',
    'log-out',
    'logout',
    'long-bottom-down',
    'long-bottom-up',
    'long-down',
    'long-left',
    'long-right',
    'long-up',
    'long-up-left-',
    'long-up-right',
    'mail',
    'mail-open',
    'manufacturing',
    'map',
    'mark-map',
    'megaphone',
    'megaphone-add',
    'menu-alt-01',
    'menu-alt-02',
    'menu-alt-03',
    'menu-alt-04',
    'menu-alt-05',
    'menu-duo',
    'minus',
    'minus-circle',
    'minus-circle-outline',
    'minus-square',
    'money-bag',
    'moon',
    'more-horizontal',
    'more-vertical',
    'move',
    'move-horizontal',
    'move-vertical',
    'news',
    'note',
    'note-list',
    'off-close',
    'off-outline-close',
    'origin',
    'outline',
    'path',
    'pause-circle-filled',
    'pause-circle-outline',
    'pencil',
    'pharmacy',
    'phone',
    'picks-of-sales',
    'pie-chart',
    'pie-chart-25',
    'pie-chart-50',
    'pie-chart-75',
    'pie-chart-outline',
    'pie-chart-outline-25',
    'pills',
    'planning',
    'play-arrow',
    'play-circle-filled',
    'play-circle-outline',
    'plus',
    'plus-circle',
    'plus-circle-outline',
    'plus-square',
    'profile',
    'radio',
    'radio-filled',
    'refresh',
    'refresh-02',
    'region',
    'region-all',
    'remove',
    'repeat',
    'sad',
    'sales',
    'search',
    'search-small',
    'search-small-minus',
    'search-small-plus',
    'search-loupe',
    'select-multiple',
    'send',
    'send-email',
    'settings',
    'settings-filled',
    'settings-future',
    'share',
    'share-outline',
    'shopping-bag',
    'short-down',
    'short-left',
    'short-right',
    'short-up',
    'show',
    'shrink',
    'shuffle',
    'skip-next',
    'skip-previous',
    'slider-01',
    'slider-02',
    'slider-03',
    'small-long-down',
    'small-long-left',
    'small-long-right',
    'small-long-up',
    'start-full',
    'start-outline',
    'statistic',
    'stocs',
    'stopwatch',
    'sub-left',
    'sub-right',
    'sun',
    'tag',
    'tag-outline',
    'text-align-center',
    'text-align-justify',
    'text-align-left',
    'text-align-right',
    'thin-big-down',
    'thin-big-left',
    'thin-big-right',
    'thin-big-up',
    'thin-long-02-down',
    'thin-long-02-left',
    'thin-long-02-right',
    'thin-long-02-up',
    'thin-long-down',
    'thin-long-left',
    'thin-long-right',
    'thin-long-up',
    'trash-empty',
    'trash-full',
    'trending-down',
    'trending-up',
    'unfold-less',
    'unfold-more',
    'unlink',
    'up-sales',
    'user-block',
    'user-unblock',
    'users',
    'unlock',
    'visibility-off',
    'visibility-on',
    'waiting',
    'warning',
    'warning-outline'
] as const;
