import type { Relationships } from '@/models';

export enum CompanyStatus {
    /**
     * Активные
     */
    ACTIVE = 'active',

    /**
     * Не активные
     */
    INACTIVE = 'inactive',

    /**
     * Заявка на сотрудничество
     */
    REQUEST = 'request',

    /**
     * Приглашенные
     */
    INVITED = 'invited',

    /**
     * Заблокированные
     */
    BLOCKED = 'blocked',

    /**
     * Не подтвержденная
     */
    UNCONFIRMED = 'unconfirmed'
}

/**
 * Тип регистрации компании
 */
export type CompanyType = 'RESIDENT' | 'IP' | 'NON-RESIDENT';

/**
 * Этапы заполненных форм при регистрации
 */
export enum CompanyRegistrationStage {
    PRE_REGISTRATION = 'PRE-REGISTRATION',
    COMMON = 'COMMON',
    BANK_ACCOUNTS = 'BANK-ACCOUNTS',
    FORM = 'FORM',
    DOCUMENTS = 'DOCUMENTS',
    CONTRACT = 'CONTRACT',
    COMPLETE = 'COMPLETE',
    ADDITIONAL = 'ADDITIONAL'
}

/**
 * Система налогообложения компании
 */
export type CompanyTaxSystemType =
    | 'COMMON' // Общая (с НДС)
    | 'SIMPLE'; // Упрощённая (без НДС)

export type CompanyLegalFormType =
    | 'ГУП'
    | 'ГКП'
    | 'ПТ'
    | 'МУП'
    | 'ГО'
    | 'АО'
    | 'ОО'
    | 'НП'
    | 'ИП'
    | 'ОКУ'
    | 'ООО'
    | 'ОАО'
    | 'ПБОЮЛ'
    | 'учр.'
    | 'ПК'
    | 'фонд'
    | 'ГБУ'
    | 'ФЛ'
    | 'ПАО'
    | 'МУ'
    | 'ГБУЗ'
    | 'АНО'
    | 'ЗП'
    | 'ГК'
    | 'ГКУЗ'
    | 'ГУ'
    | 'ОДО'
    | 'ПО'
    | 'ОЮЛ'
    | 'ПКООП'
    | 'ФБУЗ'
    | 'ЗАО';

/**
 * Данные компаний
 */
export interface CompanyAttributes {
    id?: number;
    name: null | string;
    advertiserId: null | number;
    inn: string | null;
    wabcCode: null | number;
    vendorId: null | number;
    registrationStage: null | CompanyRegistrationStage;
    type: null | CompanyType;
    taxSystem: null | CompanyTaxSystemType;
    kpp: null | string;
    legalForm: null | CompanyLegalFormType;
    ogrn: null | string;
    ogrnip: null | string;
    ogrnDate: null | string;
    oktmo: null | string;
    telephone: null | string;
    site: null | string;
    addressMatch: null | boolean;
    countUsers: number;
    activeUsers: number;
    status: CompanyStatus;
    startDateReports: null | string;
    endDateReports: null | string;
    advertisingDiscount: null | number;
    systemId: number;
    fullForm: string;
    readonly createdAt: string;
    readonly updatedAt: string;
}

export interface Company {
    type: 'companies';
    id: number;
    attributes: CompanyAttributes;
    relationships: Relationships;
}
