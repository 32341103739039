import { defineStore } from 'pinia';
import { ref } from 'vue';

export const initLogoutEventHandler = ({
    logout,
    openLoginPage,
    rememberLocation,
    notifyClear
}: {
    logout: () => Promise<void>;
    openLoginPage: () => Promise<void>;
    notifyClear: () => void;
    rememberLocation: () => void;
}) => {
    let catchLogout = false;
    document.addEventListener('logout', async () => {
        try {
            if (!catchLogout) {
                catchLogout = true;
                rememberLocation();

                await logout();
                if (window.location.pathname !== 'login') {
                    await openLoginPage();
                }
                notifyClear();
            }
        } finally {
            catchLogout = false;
        }
    });
};

export const useRememberLocationStore = defineStore('rememberLocation', () => {
    const rememberedLocation = ref<string | null>(null);

    const remember = () => {
        const ignorePaths = ['/login', '/', '/forbidden', '/downtime'];
        if (!ignorePaths.includes(window.location.pathname)) {
            const relativeUrl = window.location.href.replace(window.location.origin, '');
            rememberedLocation.value = relativeUrl;
        }
    };

    return {
        remember,
        clear() {
            rememberedLocation.value = null;
        },
        rememberedLocation
    };
});
