import { CustomError } from '@/errors/CustomError';

export class NetworkError extends CustomError {
    readonly name: string = 'NetworkError';

    constructor(
        message = 'Не найдено',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        readonly response?: any
    ) {
        super(message);
    }
}
