import type { Directive } from 'vue';

/**
 * Директива для отслеживание клика за пределами выбранного блока
 * После определения outside клика выполняется переданный метод
 *
 * <div v-click-outside="closeDropdownMenu">...</div>
 */

type ElementClickOutside = Element & { clickOutsideHandler: (event: Event) => void };

export default {
    beforeMount(element: ElementClickOutside, binding: { value?: () => void }) {
        const { value: handler } = binding;

        if (typeof handler !== 'function') return;
        element.clickOutsideHandler = (event: Event) => {
            event.stopPropagation();

            if (event.target === element || element.contains(event.target as Element)) {
                return;
            }

            handler();
        };

        document.addEventListener('mousedown', element?.clickOutsideHandler, false);
    },

    beforeUnmount(element: ElementClickOutside) {
        if (element?.clickOutsideHandler) {
            document.removeEventListener('mousedown', element.clickOutsideHandler, false);
        }
    }
} as Directive<ElementClickOutside, VoidFunction>;
