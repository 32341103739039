import { PlacementTypes } from '@/entities/Placement';

export interface PresetFormatParams {
    contentType: PlacementTypes.PlacementContentType;
    type: PlacementTypes.PlacementType;
    paramType: PlacementTypes.PlacementParamType;
}

const formatTypes = (type: PlacementTypes.PlacementType) => {
    return PlacementTypes.PlacementType[type];
};

const formatContentTypes = (contentType: PlacementTypes.PlacementContentType) => {
    return PlacementTypes.PlacementContentType[contentType];
};

const formatParamTypes = (paramType: PlacementTypes.PlacementParamType) => {
    return PlacementTypes.PlacementParamType[paramType];
};

export const presetFormatParams = (params: PresetFormatParams) => {
    return {
        types: formatTypes(params.type),
        contentTypes: formatContentTypes(params.contentType),
        paramTypes: formatParamTypes(params.paramType)
    };
};
