<template>
    <div class="notification" :class="{ 'notification--collapsed': isCollapsed }" :style="widthSize">
        <div class="notification__icon" @click="toggleCollapse">
            <PIcon v-if="iconName" :name="iconName" />
        </div>

        <div class="notification__content">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="notification__title" v-html="sanitizedTitle"></div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="notification__text" v-html="sanitizedText"></div>
        </div>

        <div class="notification__action">
            <PButton icon="chevron-big-right" variant="text" @click="toggleCollapse" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import type { IconName } from '@/shared/model/types/Icons';
import { PButton, PIcon } from '@/shared/ui';
import type { NotifyType } from '../../model/NotifyTypes';
import DOMPurify from 'dompurify';

/**
 * Этот тип дубль CollapseNotifyProps, так потому, что импорт типов
 * для объявления props в setup компонентах не поддерживается.
 * SEE: https://github.com/vuejs/rfcs/blob/master/active-rfcs/0040-script-setup.md#type-only-propsemit-declarations
 * Типы дублируются, чтобы избежать циклических зависимостей
 */
export interface Props {
    title: string;
    text: string;
    width?: string | number;
    type?: NotifyType;
}

const props = withDefaults(defineProps<Props>(), {
    width: 600,
    type: 'info'
});

const sanitizedTitle = computed(() => DOMPurify.sanitize(props.title));
const sanitizedText = computed(() => DOMPurify.sanitize(props.text));

const iconName = computed<IconName>(() => {
    const type = props.type || 'info';
    const icons: Record<typeof type, IconName | ''> = {
        info: 'info-circle-outline',
        success: 'info-circle-outline',
        error: 'warning-outline',
        warning: 'warning-outline',
        download: '',
        invite: ''
    };
    return icons[type] || 'info';
});

const widthSize = computed<{ width: string }>(() => {
    return {
        width: props.width + 'px'
    };
});

const isCollapsed = ref(false);
const toggleCollapse = () => {
    isCollapsed.value = !isCollapsed.value;
};
</script>

<style scoped lang="scss">
.notification {
    display: grid;
    grid-template-columns: 4.8rem 1fr 5rem;
    font-size: var(--text-size-14);
    color: var(--text-color);
    background-color: var(--white);
    box-shadow: var(--shadow-notification);
    border-radius: var(--border-radius-8) 0 0 var(--border-radius-8);
    transition: width 0.4s ease-in-out;

    &--collapsed {
        width: 50px !important;
    }

    &__icon {
        --accent-color: var(--primary-light);

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        color: var(--accent-color);
        border-right: 2px solid var(--accent-color);
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--accent-color);
            opacity: 0.05;
        }
    }

    &__content {
        padding: 2rem;
        display: grid;
        grid-gap: 1rem;
        line-height: 1.4;
    }

    &__title {
        font-size: initial;
        font-weight: 700;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__text {
        font-size: initial;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__action {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 3rem;
    }
}
</style>
