import { omit } from 'lodash';
import client from '@/shared/api/client';
import type { Placement, PlacementId } from '../model/Placement';
import { PlacementError } from '../model/PlacementError';
import type { Nullable } from '@/shared/model/types/Kernel';

const formatDataToServer = (placement: Placement) => {
    return omit(placement, ['id', 'adId', 'adTag', 'sizes']);
};

export const getPlacements = async (): Promise<Placement[]> => {
    const { data } = await client.get('/api/adserver/placements');
    return data;
};

export const getPlacementById = async (id: PlacementId): Promise<Placement> => {
    const { data } = await client.get(`/api/adserver/placement/${id}`);
    return data;
};

export const createPlacement = async (payload: Nullable<Omit<Placement, 'id' | 'adId'>>) => {
    const { data } = await client.post('/api/adserver/placement', payload).catch(error => {
        if (error.response) {
            throw new PlacementError(error.response.data.type, error.response);
        }
        throw error;
    });
    return data;
};

export const updatePlacement = async (payload: Placement) => {
    const { data } = await client
        .put(`/api/adserver/placement/${payload.id}`, formatDataToServer(payload))
        .catch(error => {
            if (error.response) {
                throw new PlacementError(error.response.data.type, error.response);
            }
            throw error;
        });
    return data;
};

export const deletePlacementById = async (id: PlacementId): Promise<void> => {
    await client.delete(`/api/adserver/placement/${id}`);
};
