<template>
    <h2>Выход</h2>

    <p>
        Вы уверены что хотите выйти<br />
        из личного кабинета?
    </p>

    <div>
        <PButton full @click="logout"> Выйти </PButton>
    </div>
</template>

<script lang="ts" setup>
import { PButton } from '@/shared/ui';

import * as ProcessAuth from '@/processes/Auth';
import { useRouter } from 'vue-router';

const router = useRouter();
const logout = () => {
    ProcessAuth.logout();
    router.push({ name: 'login' });
};
</script>
