import type { CompanyId } from '@/shared/model/types/Company';
import { LocalStorageService, logger } from '@/shared/model/utils';
import type { UserId } from '@/shared/model/types/User';

interface DataParseToken {
    activeCompanyId: CompanyId;
    email: string;
    exp: number;
    iat: number;
    sub: UserId;
}

const ACCESS_TOKEN_KEY = 'accessToken';
const IMPERSONATE_TOKEN_KEY = 'impersonateToken';

export const accessToken = new LocalStorageService<string>(ACCESS_TOKEN_KEY);
export const impersonateToken = new LocalStorageService<string>(IMPERSONATE_TOKEN_KEY);

const parseJWT = (token: string | null) => {
    try {
        if (!token) return null;

        return JSON.parse(window.atob(token.split('.')[1]));
    } catch (error) {
        logger.error(error);
        return null;
    }
};

export const getDataFromToken = (token: string): DataParseToken => parseJWT(token);

export const removeToken = () => {
    [impersonateToken, accessToken].forEach(token => token.remove());
};

const getTokenValue = () => {
    return impersonateToken.get() || accessToken.get();
};

export const setAccessToken = (token: string) => {
    if (impersonateToken.get()) {
        impersonateToken.set(token);
    } else {
        accessToken.set(token);
    }
};

export const getToken = () => {
    return getTokenValue();
};

export const hasToken = () => Boolean(getTokenValue());
