import { notifyError } from './showNotify';
import sentry from '../../lib/sentry/sentry';
import { logger } from './logger';

export const captureError = (
    error: unknown,
    message: ((error?: unknown) => string) | string = 'Неизвестная ошибка'
) => {
    notifyError({ title: 'Ошибка', text: typeof message === 'function' ? message(error) : message });
    logger.error(error);
    sentry.captureException(error);
};
