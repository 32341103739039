// костыль, пока не сделали систему настройки доступов
export const isProtekCompany = (companyName: unknown): boolean => {
    if (typeof companyName !== 'string' || !companyName) {
        return false;
    }

    return ['ЗАО ЦВ Протек', 'АО ЦВ Протек'].includes(companyName);
};

export const isNumericString = (num: string): boolean => num !== '' && !Number.isNaN(Number(num));
