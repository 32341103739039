import type { Role } from '@/shared/model/types/Role';
import client from '@/shared/api/client';
import type { PermissionId } from '@/shared/model/types/Permission';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toClient = (data: any): Role => {
    return {
        id: data.id,
        permissionIds: data.relationships.permissions.map((p: { id: PermissionId }) => p.id),
        ...data.attributes
    } as Role;
};

export const getRoles = async (): Promise<Role[]> => {
    const response = await client.get('/api/roles');
    return response.data.data.map(toClient);
};
