/**
 * Функция для валидации штрих-кода
 * Описание проверки контрольной сумму: https://barcode-coder.com/en/ean-8-specification-101.html
 * Описание типов EAN8 и EAN13: https://www.barcodefaq.com/barcode-match/
 */
//
export const isValidBarcode = (value: string | number): boolean => {
    const EAN8_LENGTH = 8;
    const EAN13_LENGTH = 13;

    value = String(value);

    if (![EAN8_LENGTH, EAN13_LENGTH].includes(value.length)) {
        return false;
    }

    if (!/[\d+]/.test(value)) {
        return false;
    }

    const paddedValue = value.padStart(13, '0');

    let oddSum = 0;
    let evenSum = 0;

    for (let i = 0; i < paddedValue.length - 1; i++) {
        const digit = Number(paddedValue[i]);

        if (i % 2 === 0) {
            evenSum += digit;
        } else {
            oddSum += digit;
        }
    }

    const checksum = (10 - ((3 * oddSum + evenSum) % 10)) % 10;
    const expectedChecksum = Number(paddedValue.at(-1));

    return checksum === expectedChecksum;
};
