import type { Brand, dateISO } from '@/shared/model/types/Kernel';
import type { ActionPromoTypeAction } from './ActionPromoTypeAction';
import type { BranchId } from '@/entities/Branch/model/Branch';
import type { MedicineId } from '@/entities/Medicine/model/Medicine';
import type { SegmentId } from '@/entities/Segment/model';

export type ActionPromoId = Brand<number, 'actionPromo'>;

export enum ActionPromoChannel {
    B2B = 1
}

export enum SaleInPriceBonusMetric {
    PERCENT = '% снижения цены'
}

export enum BonusPackageBonusMetric {
    PACKAGES = 'Упаковки',
    RUBLES = 'Рубли'
}

export enum ItemMetric {
    PACKAGE = 'Упаковки',
    NDS = 'с НДС',
    WITHOUT_NDS = 'без НДС'
}

export interface ActionPromoItem {
    // [0] = Весь ассортимент Протек
    itemCode: MedicineId[];
    itemMultiplicity: null | number;
    itemMetrics: ItemMetric;
    nunBegin: null; // размер начальной закупки препарата
    nunEnd: null; // это поле не выводится
    bonusMetrics: SaleInPriceBonusMetric | BonusPackageBonusMetric;
    bonusItemCode: null | MedicineId;
    bonusValue: number;
    growthPerc: null; // что это ???
    providerCodeAssortment?: number | null;
    purchaseAmountStart?: number | null;
    purchaseAmountEnd?: number | null;
}

export interface ActionPromoExceptionItem {
    exclusionFlag: boolean;
    clientPart: string[];
    branchPart: BranchId[];
    segmentPart: SegmentId[];
}

export enum ActionPromoBonusPackageSubTypeAction {
    BY_PACKAGE = 1,
    BY_RUBLE = 2
}

export interface ActionPromo {
    channel: ActionPromoChannel;
    typeAction: ActionPromoTypeAction;
    subTypeAction: null | ActionPromoBonusPackageSubTypeAction;
    actionName: string;
    note: string;
    providerCode: number;
    startDate: dateISO;
    endDate: dateISO;
    basePeriodStart: dateISO | null;
    basePeriodEnd: dateISO | null;
    actionLines: ActionPromoItem[];
    actionExceptions: ActionPromoExceptionItem[];
    noteExpanded: string;
    linkExternalFile: string;
}
