import { configure } from 'vee-validate';
import { setLocale, localize } from '@vee-validate/i18n';
import ru from './locale';
import './rules';

configure({
    generateMessage: localize({
        ru
    })
});

setLocale('ru');
