<template>
    <div class="notification">
        <div class="notification__icon">
            <PIcon name="info-square" />
        </div>

        <div class="notification__content">
            <div class="notification__title">Ссылка на приглашение</div>
            <div class="notification__text">
                Для доступа в систему отправьте ссылку сотруднику напрямую или отправьте приглашение на почту
                <a :href="`mailto:${email}`">
                    {{ email }}
                </a>
            </div>

            <PButton
                class="copy-link"
                variant="text"
                small
                title="Скопировать"
                :icon="isCopy ? 'circle-check-outline' : 'copy'"
                :color="isCopy ? 'success' : 'primary'"
                @click="copyToClipboard"
            >
                {{ isCopy ? 'Скопировано в буфер обмена' : link }}
            </PButton>

            <template v-if="isSendError">
                <PButton small variant="text" icon="send" color="error"> Не удалось отправить приглашение </PButton>
            </template>
            <template v-else>
                <PButton
                    small
                    variant="text"
                    title="Отправить приглашение"
                    :icon="isSend ? 'circle-check-outline' : 'send'"
                    :color="isSend ? 'success' : 'primary'"
                    @click="sendInvite"
                >
                    {{ isSend ? 'Приглашение отправлено' : `Отправить приглашение на почту` }}
                </PButton>
            </template>
        </div>

        <div class="notification__action">
            <PButton icon="close" variant="text" @click="closeItem" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PButton, PIcon } from '@/shared/ui';
import { ref } from 'vue';
import { copyToClipboard as _copyToClipboard } from '@/utils';

/**
 * Этот тип дубль InviteNotifyProps, так потому, что импорт типов
 * для объявления props в setup компонентах не поддерживается.
 * SEE: https://github.com/vuejs/rfcs/blob/master/active-rfcs/0040-script-setup.md#type-only-propsemit-declarations
 * Типы дублируются, чтобы избежать циклических зависимостей
 */
interface Props {
    email: string;
    link: string;
    sendEmail: () => Promise<unknown>;
}
const props = defineProps<Props>();

const isCopy = ref(false);
const isSend = ref(false);
const isSending = ref(false);
const isSendError = ref(false);

const emit = defineEmits<(e: 'close') => void>();

const closeItem = () => {
    emit('close');
};

const copyToClipboard = async () => {
    isCopy.value = true;
    await _copyToClipboard(props.link);
    await new Promise(resolve => setTimeout(resolve, 2_000));
    isCopy.value = false;
};

const sendInvite = async () => {
    if (isSending.value) return;

    isSending.value = true;
    try {
        await props.sendEmail();
        isSend.value = true;
    } catch (_error) {
        isSendError.value = true;
    } finally {
        setTimeout(() => {
            isSend.value = false;
            isSending.value = false;
            isSendError.value = false;
            // не знаю почему 2 секунды задержка
        }, 2000);
    }
};
</script>

<style scoped lang="scss">
@import '@/shared/styles/notification';

.copy-link {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
