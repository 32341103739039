import type { Brand } from '@/shared/model/types/Kernel';
import type { Company, CompanyId } from '@/shared/model/types/Company';
import type { MedicineId } from '@/entities/Medicine';
import type { User, UserId } from '@/shared/model/types/User';
import type { Promotion, PromotionId } from './Promotion';

export type PromotionRequestProductId = Brand<number, 'PromotionRequestProduct'>;
export type PromotionRequestId = Brand<number, 'PromotionRequest'>;

export enum PromotionRequestStatus {
    /* Заявка принята/одобрена менеджером */
    ACCEPTED = 'ACCEPTED',
    /* Статус заявки сразу после создания */
    MODERATION = 'MODERATION',
    /* Менеджер вернул заявку на доработку */
    PENDING = 'PENDING',
    /* Заявка отклонена менеджером */
    DECLINED = 'DECLINED'
}

export enum PromotionRequestProductStatus {
    /* Новая товарная позиция */
    NEW = 'NEW',
    /* Товар отклонён менеджером */
    DECLINED = 'DECLINED'
}

export interface PromotionRequest {
    id: PromotionRequestId;
    promotionId: PromotionId;
    companyId: CompanyId;
    userId: UserId;
    status: PromotionRequestStatus;
    guaranteeLetterFileName: string;
    guaranteeConfirmed: boolean;
    comment: string | null;
    readonly productsCount: number;
    promotion: Promotion;
    company: Company;
    products: PromotionRequestProduct[];
    managerComment: string | null;
}

export interface PromotionRequestProduct {
    id: PromotionRequestProductId;
    requestId: PromotionRequestId;
    protekCode: MedicineId;
    name: string;
    status: PromotionRequestProductStatus;
    discount: number;
    multiplicity: number;
    comment: string | null;
}

export interface PromotionRequestRelations {
    company: Company[];
    promotion: Promotion[];
    user: User[];
    products: PromotionRequestProduct[];
}
