import type { AxiosResponse } from 'axios';
import { NetworkError } from '@/errors/NetworkError';

enum PlacementErrorType {
    TEXT_ID = 'TEXT_ID_VALIDATION_ERROR'
}

const ERROR_TYPE = new Map<string, string>([
    [PlacementErrorType.TEXT_ID, 'Данное значение идентификатора уже существует']
]);

export class PlacementError extends NetworkError {
    readonly name = 'PlacementError';

    constructor(
        readonly type: string,
        readonly response: AxiosResponse
    ) {
        const message = ERROR_TYPE.get(type) ?? `Неизвестный тип ошибки ${type}`;
        super(message, response);
    }

    get isPlacementTextIdExistError(): boolean {
        return this.type === PlacementErrorType.TEXT_ID;
    }
}
