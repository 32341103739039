import { createApp } from 'vue';
import '@/shared/lib/yup/yup';
import { router } from './router';
import App from './App.vue';

import directives from '@/shared/directives';
import plugins from '@/shared/plugins';
import '@/shared/styles/index.scss';
import { createPinia } from 'pinia';
import sentry from '@/shared/lib/sentry/sentry';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { notifyClear } from '@/shared/model/utils/showNotify';
import { logger } from '@/shared/model/utils';
import * as ProcessAuth from '@/processes/Auth';
import Analytics from '@/shared/lib/analytics/Analytics';
import { initLogoutEventHandler, useRememberLocationStore } from '@/shared/model/store/RememberLocationStore';
import { useStore } from '@/shared/model/store/Store';

const pinia = createPinia();

Analytics.init();

(async () => {
    const app = createApp(App);

    try {
        sentry.init(app, router);

        app.use(pinia);

        directives(app);
        plugins(app);

        // нужно до инициализации router,
        // т.к router middleware зависят от состояния store
        const store = useStore();
        await store.init();
    } catch (error) {
        sentry.captureException(error);
        logger.error(error);
    } finally {
        app.use(router);
        await router.isReady();
        app.mount('#app');
    }
})();

initLogoutEventHandler({
    logout: ProcessAuth.logout,
    openLoginPage: async () => {
        await router.push({ name: 'login' });
    },
    rememberLocation: () => {
        const rememberLocationStore = useRememberLocationStore();
        rememberLocationStore.remember();
    },
    notifyClear
});
