import { plugin as VueTippy } from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import './styles.scss';
import type { App } from 'vue';

export default (app: App) => {
    app.use(VueTippy, {
        directive: 'tooltip',
        component: 'p-tooltip',
        defaultProps: {
            theme: 'light',
            arrow: true,
            animation: 'fade',
            offset: [0, 14]
        }
    });
};
