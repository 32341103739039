import { NetworkError } from '@/errors/NetworkError';
import type { AxiosResponse } from 'axios';

export class ForbiddenError extends NetworkError {
    readonly name: string = 'Forbidden';

    constructor(
        message: string,
        readonly response: AxiosResponse
    ) {
        super(message, response);
    }
}
