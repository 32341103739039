import type { AxiosError } from 'axios';
import { NetworkError } from '@/errors/NetworkError';

/**
 * Список ошибок для методов создания и привязки товара
 *
 *  Bad Request (400) для /products:
 *    products:pharma-data:submit:code-already-used - товар с таким кодом уже используется
 *
 *  Bad Request (400) для /submit:
 *    products:pharma-data:submit:no-producer - не указан производитель
 *    products:pharma-data:submit:no-priority - не указан приоритет
 *    products:pharma-data:submit:no-jnvlp-register-price - для продукта с флагом ЖНВЛП не указана реестровая цена
 *    products:pharma-data:submit:no-user-active-company - не выбрана активная компания
 *    products:pharma-data:submit:no-company-system-id - не указан systemId
 *    products:pharma-data:submit:no-code - не удалось найти код продукта
 *
 *  Internal Server Error (500) для /submit:
 *    products:pharma-data:submit:create-pharma-data-binding-error - не удалось создать связку в PharmaData
 *    products:pharma-data:submit:create-pharma-data-product-error - не удалось отправить атрибуты продукта в PharmaData
 *    products:pharma-data:submit:create-pharma-data-files-error - не удалось отправить заявку на создание файла в PharmaData
 *    products:pharma-data:submit:delete-pharma-data-files-error - не удалось отправить заявки на удаление файла в PharmaData
 *    products:pharma-data:submit:fields-validation-error - Ошибка валиадции полей формы
 *    products:pharma-data:submit:files-validation-error - Ошибка валиадции файлов
 */

export enum ProductErrorType {
    CODE_ALREADY_USED = 'products:pharma-data:submit:code-already-used',
    NO_PRODUCER = 'products:pharma-data:submit:no-producer',
    NO_PRIORITY = 'products:pharma-data:submit:no-priority',
    CATEGORY_NO_ES_CATEGORY_ERROR = 'products:pharma-data:submit:category-no-es-category-error',
    NO_JNVLP_REGISTER_PRICE = 'products:pharma-data:submit:no-jnvlp-register-price',
    NO_USER_ACTIVE_COMPANY = 'products:pharma-data:submit:no-user-active-company',
    NO_COMPANY_SYSTEM_ID = 'products:pharma-data:submit:no-company-system-id',
    NO_CODE = 'products:pharma-data:submit:no-code',
    CREATE_PHARMA_DATA_BINDING_ERROR = 'products:pharma-data:submit:create-pharma-data-binding-error',
    CREATE_PHARMA_DATA_PRODUCT_ERROR = 'products:pharma-data:submit:create-pharma-data-product-error',
    CREATE_PHARMA_DATA_FILES_ERROR = 'products:pharma-data:submit:create-pharma-data-files-error',
    DELETE_PHARMA_DATA_FILES_ERROR = 'products:pharma-data:submit:delete-pharma-data-files-error',
    FIELDS_VALIDATION_ERROR = 'products:pharma-data:submit:fields-validation-error',
    FILES_VALIDATION_ERROR = 'products:pharma-data:submit:files-validation-error'
}

const productErrorTypeMessageMap = new Map<ProductErrorType, string>([
    [ProductErrorType.CODE_ALREADY_USED, 'Товар с таким кодом уже используется'],
    [ProductErrorType.NO_PRODUCER, 'Не указан производитель'],
    [ProductErrorType.NO_PRIORITY, 'Не указан приоритет'],
    [ProductErrorType.CATEGORY_NO_ES_CATEGORY_ERROR, 'У категории не корректно указано значение при заполении формы'],
    [ProductErrorType.NO_JNVLP_REGISTER_PRICE, 'Для продукта с флагом ЖНВЛП не указана реестровая цена'],
    [ProductErrorType.NO_USER_ACTIVE_COMPANY, 'Не выбрана активная компания'],
    [ProductErrorType.NO_COMPANY_SYSTEM_ID, 'Не указан systemId'],
    [ProductErrorType.NO_CODE, 'Не удалось найти код продукта'],
    [ProductErrorType.CREATE_PHARMA_DATA_BINDING_ERROR, 'Не удалось создать связку в PharmaData'],
    [ProductErrorType.CREATE_PHARMA_DATA_PRODUCT_ERROR, 'Не удалось отправить атрибуты продукта в PharmaData'],
    [ProductErrorType.CREATE_PHARMA_DATA_FILES_ERROR, 'Не удалось отправить заявку на создание файла в PharmaData'],
    [ProductErrorType.DELETE_PHARMA_DATA_FILES_ERROR, 'Не удалось отправить заявки на удаление файла в PharmaData'],
    [ProductErrorType.FIELDS_VALIDATION_ERROR, 'Ошибка валидации полей формы'],
    [ProductErrorType.FILES_VALIDATION_ERROR, 'Ошибка валидации файлов']
]);

export const checkProductError = (errorType: ProductErrorType) => {
    return Object.values(ProductErrorType).includes(errorType);
};

export class ProductError extends NetworkError {
    readonly name: string = 'ProductError';

    constructor(
        readonly response: AxiosError,
        readonly type: ProductErrorType
    ) {
        const message = productErrorTypeMessageMap.get(type) || 'Произошла ошибка при обработке данных товара';
        super(message, response);
    }
}
