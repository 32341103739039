import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { CompanyId } from '@/shared/model/types/Company';
import { Unauthorized } from '@/errors/Unauthorized';
import { getToken } from '@/shared/model/TokenService';
import { useUserStore } from '@/shared/model/store/UserStore';
import { useAccessStore } from '@/shared/model/store/AccessStore';
import { notifyError } from '@/shared/model/utils/showNotify';

export const useStore = defineStore('store', () => {
    const isInitApp = ref(false);
    const isErrorInitApp = ref(false);

    const init = async (companyId?: CompanyId) => {
        try {
            isInitApp.value = true;
            const token = getToken();

            if (token) {
                const { fetchUser } = useUserStore();
                await fetchUser(companyId);
                const accessStore = useAccessStore();
                await accessStore.init();
            }
        } catch (error) {
            if (error instanceof Unauthorized) {
                return;
            }

            notifyError({
                title: 'Ошибка',
                text: 'Произошла ошибка при инициализации данных приложения'
            });

            isErrorInitApp.value = true;
            throw error;
        } finally {
            isInitApp.value = false;
        }
    };

    return {
        init,
        isInitApp,
        isErrorInitApp
    };
});
