import { DepartmentType, MechanicType, PromotionType } from './Promotion';
import { assertNever } from '@/shared/model/types/Kernel';
import { PromotionRequestStatus } from '@/entities/Promotion';

export const getDepartmentTypeName = (department: DepartmentType): string => {
    switch (department) {
        case DepartmentType.ONLS:
            return 'ОНЛС';
        case DepartmentType.OZ:
            return 'ОЗ';
    }
};

export const getPromotionTypeName = (promotionType: PromotionType): string => {
    switch (promotionType) {
        case PromotionType.CATEGORY:
            return 'Категория';
        case PromotionType.INDIVIDUAL:
            return 'Индивидуальные предложение';
        case PromotionType.SPECIAL_DAY:
            return 'Специальный день';
        case PromotionType.SEASON:
            return 'Сезонная подборка';
        case PromotionType.SALE:
            return 'Распродажа';
    }
};

/**
 * На данный момент, значение MechanicType имеет одно значение
 * @param mechanic
 */
export const getMechanicTypeName = (mechanic: MechanicType): string => {
    if (MechanicType.STRICT === mechanic) {
        return 'Прямая';
    }

    assertNever(mechanic);
};

export const getPromotionRequestStatusName = (status: PromotionRequestStatus): string => {
    switch (status) {
        case PromotionRequestStatus.ACCEPTED:
            return 'Согласованные';
        case PromotionRequestStatus.MODERATION:
            return 'На проверке';
        case PromotionRequestStatus.DECLINED:
            return 'Отклонено';
        case PromotionRequestStatus.PENDING:
            return 'В ожидании';
    }
};

export const promotionTypeOptions = [PromotionType.SALE, PromotionType.SEASON, PromotionType.SPECIAL_DAY].map(
    promotionType => ({
        id: promotionType,
        name: getPromotionTypeName(promotionType)
    })
);
