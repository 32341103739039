import { ability } from '@/shared/lib/casl/casl';

export const usePermissions = () => {
    const { can } = ability;

    const canRead = () => can('read', 'BookingCRUD');

    const canReadBookingPresets = () => can('read', 'BookingPresets');

    return {
        canRead,
        canReadBookingPresets
    };
};
