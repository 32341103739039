import { ability } from '@/shared/lib/casl/casl';

export const usePermissions = () => {
    const { can } = ability;

    const canManage = () => can('manage', 'BookingCalendar');

    return {
        canManage
    };
};
