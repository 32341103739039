import type { AxiosResponse } from 'axios';
import { NetworkError } from '@/errors/NetworkError';

const PRODUCT_CATEGORY_SETTINGS_ERROR_TYPE = new Map<string, string>([
    ['CATEGORY_WITH_SAME_LOGISTIC_SCHEME_ALREADY_EXIST', 'Категория с такой логистической схемой уже существует'],
    [
        'CATEGORY_WITH_SAME_KEY_IN_THIS_BRANCH_ALREADY_EXISTS',
        'Категория с таким же ключом в этой вложенности уже существует'
    ],
    [
        'CATEGORY_WITH_SAME_ES_CATEGORY_IN_THIS_BRANCH_ALREADY_EXISTS',
        'Категория с таким же типом категории ЕС уже существует'
    ]
]);

export const isProductCategorySettingsError = (errorType: string) => {
    return PRODUCT_CATEGORY_SETTINGS_ERROR_TYPE.has(errorType);
};

export class ProductCategorySettingsError extends NetworkError {
    readonly name = 'ProductCategorySettingsError';

    constructor(
        readonly response: AxiosResponse,
        readonly type: string
    ) {
        const message = PRODUCT_CATEGORY_SETTINGS_ERROR_TYPE.get(type);

        if (!message) {
            throw new NetworkError(`Ошибка обработки запроса. Неизвестный тип ${type}`, response);
        }
        super(message, response);
    }
}
