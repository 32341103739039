import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type {
    Permission,
    PermissionKey as PermissionKey,
    PermissionGroup,
    PermissionGroupKeys as PermissionGroupKey,
    PermissionDictionaryUnion
} from '../types/Permission';
import { PermissionGroupDictionary } from '../types/Permission';
import { useUserStore } from '@/shared/model/store/UserStore';
import sentry from '@/shared/lib/sentry/sentry';
import { logger } from '@/shared/model/utils';
import client from '@/shared/api/client';
import { ability } from '@/shared/lib/casl/casl';
import {
    getPermissions,
    getPermissionsReportPackage,
    getUserCompanyPermissions
} from '@/shared/api/modules/Permission';
import { getRoles } from '@/shared/api/modules/Role';
import { type Role, RolesDictionary } from '@/shared/model/types/Role';
import { PROTEK_ID } from '@/shared/config/env';
import type { ReportPermission } from '@/entities/ReportPackage';

const concatPermissions = (
    permissions: Permission[],
    oldPermissions: Map<PermissionKey, Permission>
): Map<PermissionKey, Permission> => {
    const newPermissions = new Map<PermissionKey, Permission>();
    for (const permission of permissions) {
        newPermissions.set(permission.key, permission);
    }
    return new Map([...oldPermissions, ...newPermissions]);
};

export const useAccessStore = defineStore('access', () => {
    const userStore = useUserStore();

    const isInitializing = ref(false);

    const permissions = ref<Map<PermissionKey, Permission>>(new Map());
    const roles = ref<Map<RolesDictionary, Role>>(new Map());
    const groups = ref<Map<PermissionGroupKey, PermissionGroup>>(new Map());

    const permissionsArray = computed<Permission[]>(() => [...permissions.value.values()]);

    // пермиссии и роли юзера
    const permissionsUser = ref<Set<PermissionKey>>(new Set());
    const permissionsReport = ref<ReportPermission[]>([]);
    const permissionsReportKeys = computed<Set<PermissionKey>>(() => {
        return new Set([...permissionsReport.value.map(p => p.key)]);
    });
    const permissionsPlacement = computed<Permission[]>(() => {
        const group = groups.value.get(PermissionGroupDictionary.PLACEMENT_PERMISSION_GROUP);
        if (!group) {
            return [];
        }

        return group.permissionIds
            .map(id => permissionsArray.value.find(p => p.id === id))
            .filter((p): p is Permission => !!p)
            .filter(p => p && permissionsUser.value.has(p.key));
    });
    const rolesUser = computed<Set<RolesDictionary>>(() => new Set(userStore.roles.map(r => r.key)));

    const isProtekCompany = computed(() => userStore.companyId === PROTEK_ID);
    const isAdmin = computed(() => rolesUser.value.has(RolesDictionary.ADMINISTRATOR_ROLE));
    const isManager = computed(() => isProtekCompany.value || rolesUser.value.has(RolesDictionary.MANAGER_ROLE));

    const checkPermission = (...keys: PermissionDictionaryUnion[]) =>
        keys.some(key => {
            return permissionsUser.value.has(key) || permissionsReportKeys.value.has(key);
        });

    const clear = () => {
        permissions.value.clear();
        roles.value.clear();
        groups.value.clear();

        permissionsUser.value.clear();
        permissionsReport.value = [];
    };

    const init = async () => {
        try {
            isInitializing.value = true;

            if (!userStore.isAuthorized) {
                return;
            }

            const { permissions: _permissions, groups: _groups } = await getPermissions({
                includes: ['groups']
            });
            permissions.value = concatPermissions(_permissions, permissions.value);
            groups.value = _groups.reduce<Map<PermissionGroupKey, PermissionGroup>>((groups, group) => {
                groups.set(group.key, group);
                return groups;
            }, new Map());

            const _roles = await getRoles();
            roles.value = _roles.reduce<Map<RolesDictionary, Role>>((roles, role) => {
                roles.set(role.key, role);
                return roles;
            }, new Map());

            const userId = userStore.userId;
            const companyId = userStore.companyId;
            if (!userId || !companyId) {
                logger.warn('Пользователь не авторизован или не указана компания');
                return;
            }
            const _userPermissions = await getUserCompanyPermissions(userId, companyId);
            permissions.value = concatPermissions(_userPermissions, permissions.value);
            permissionsUser.value = _userPermissions.reduce<Set<PermissionKey>>((keys, permission) => {
                keys.add(permission.key);
                return keys;
            }, new Set());

            permissionsReport.value = await getPermissionsReportPackage(userId, companyId);

            const { data: rules } = await client('/api/v1/authorization/rules');
            ability.update(rules);

            isInitializing.value = false;
        } catch (error) {
            sentry.captureException(error);
            logger.error(error);
        } finally {
            isInitializing.value = false;
        }
    };

    return {
        isInitializing,
        permissions,
        permissionsArray,
        permissionsReport,
        permissionsUser,
        checkPermission,
        init,
        clear,
        rolesUser,
        isProtekCompany,
        isAdmin,
        isManager,
        permissionsPlacement
    };
});
