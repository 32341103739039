import { createMongoAbility, type MongoAbility } from '@casl/ability';
import { useAbility } from '@casl/vue';
import { reactiveAbility } from './reactiveAbility';

export { subject } from '@casl/ability';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Abilities = any;
export type AppAbility = MongoAbility<Abilities>;

export const ability = reactiveAbility(createMongoAbility<AppAbility>());

export const useAppAbility = () => useAbility<AppAbility>();
