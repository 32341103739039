import type { Directive } from 'vue';
import { bind, unbind } from 'focus-outside';

/**
 * Директива для отслеживание фокуса за пределами выбранного блока
 * Служит для возможности навигации с клавиатуры
 *
 * <div v-focus-outside="closeDropdownMenu">...</div>
 */
export default {
    beforeMount(element: Element, binding: { value?: unknown }) {
        const { value: handler } = binding;

        if (typeof handler !== 'function') return;

        bind(element, handler);
    },

    unmounted(element: Element) {
        unbind(element);
    }
} as Directive<HTMLElement, VoidFunction>;
