import type { App } from 'vue';

import clickOutside from './clickOutside';
import focusOutside from './focusOutside';
import inputMask from './inputMask';
import loading from './loading';

export default (app: App) => {
    app.directive('click-outside', clickOutside);
    app.directive('focus-outside', focusOutside);
    app.directive('mask', inputMask);
    app.directive('loading', loading);
};
