import type { Company, CompanyId } from '@/shared/model/types/Company';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toClient = (data: any): Company => {
    return {
        id: data.id,
        ...data.attributes
    } as Company;
};

export const toServer = (company: Partial<Company> & { id: CompanyId }) => {
    return company;
};
