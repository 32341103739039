import { router } from './router';
import { logger } from '@/shared/model/utils';
import { useUserStore } from '@/shared/model/store/UserStore';
import { getToken, hasToken } from '@/shared/model/TokenService';
import sentry, { handlerFailedFetchModule } from '@/shared/lib/sentry/sentry';
import * as ProcessAuth from '@/processes/Auth';
import Analytics from '@/shared/lib/analytics/Analytics';
import { useAccessStore } from '@/shared/model/store/AccessStore';
import { useRememberLocationStore } from '@/shared/model/store/RememberLocationStore';

/**
 * Общий флаг отображения информационной страницы о недоступности приложения.
 */
const DOWNTIME_NOTIFICATION_FLAG = false;

/**
 * Router-hooks
 */
const redirectRoutes = ['login', 'password', 'registration', 'invite', 'confirmation', 'restore-password'];

router.beforeEach(async (to, from, next) => {
    if (DOWNTIME_NOTIFICATION_FLAG) {
        await ProcessAuth.logout().catch(error => logger.error(error));

        if (to.name === 'downtime-overlay') {
            return next();
        }

        return next({ name: 'downtime-overlay' });
    }

    const userStore = useUserStore();
    const rememberLocationStore = useRememberLocationStore();

    if (!hasToken()) {
        await ProcessAuth.logout().catch(error => {
            logger.error(error);
        });
    }

    if (getToken()) {
        if (userStore.isRequestChangePassword && to.name !== 'password-change') {
            return next({ name: 'password-change' });
        }
        /**
         * Для авторизованного пользователя.
         * Проигнорировать страницы входящие список redirectRoutes и redirectRequiredRoutes
         */
        if (redirectRoutes.includes(to.name as string)) {
            return next({ name: 'profile' });
        }

        if (to.matched.some(record => record.meta.isAuth)) {
            if (to.meta?.accesses?.length) {
                try {
                    for (const cb of to.meta.accesses) {
                        const hasAccess = await cb();
                        if (!hasAccess) {
                            return next({ name: '403' });
                        }
                    }
                } catch (error) {
                    sentry.captureException(error);
                    logger.error(error);
                    return next({ name: '500' });
                }
            }

            if (to.meta?.permissions?.length) {
                const accessStore = useAccessStore();
                const access = to.meta.permissions.some(key => accessStore.checkPermission(key));

                /**
                 * Если данный раздел доступен, то переходим на страницу
                 * ... если нет доступа, то переходим на страницу профиля
                 */
                if (!access) {
                    return next({ name: 'profile' });
                }
            }
        }
    }

    /**
     * Для НЕ авторизованного пользователя
     * перенаправлять на страницу login
     */
    if (!getToken() && !redirectRoutes.includes(to.name as string)) {
        rememberLocationStore.remember();
        return next({ name: 'login' });
    }

    return next();
});

router.onError((error, to) => {
    handlerFailedFetchModule(error, to.fullPath);
});

router.afterEach((to, from, failure) => {
    if (!failure) {
        Analytics.pageView(router);
    }
});
