<template>
    <svg
        viewBox="0 0 24 24"
        :class="className"
        :style="sizeClass"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMaxYMax meet"
    >
        <use :xlink:href="iconPath" />
    </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { IconName } from '@/shared/model/types/Icons';

interface Props {
    /**
     * Название иконки соответствует названию svg-файла в каталоге assets/icons-set.
     */
    name: IconName;

    /**
     * Размер иконки
     */
    size?: string | number;
}

const props = withDefaults(defineProps<Props>(), {
    size: '24'
});

const iconPath = computed<string>(() => {
    return `/icons/sprite.svg#${props.name}`;
});
const className = computed<string>(() => {
    return 'svg-icon svg-icon--' + props.name;
});
const sizeClass = computed<Record<string, string>>(() => {
    return {
        '--size': `${props.size}px`
    };
});
</script>

<style scoped>
.svg-icon {
    --size: 24px;

    display: inline-block;
    vertical-align: middle;

    fill: currentColor;
    min-width: var(--size);
    height: var(--size);
}

.svg-icon:focus {
    outline: none;
}
</style>
