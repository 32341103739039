import { snakeCase } from 'lodash';
import type { Router } from 'vue-router';
import type { EventBase } from '@/shared/lib/analytics/types';

export const loadScript = (src: string, onload?: ((this: GlobalEventHandlers, ev: Event) => unknown) | null): void => {
    const script = document.createElement('script');
    script.setAttribute('src', src);
    if (onload) {
        script.onload = onload;
    }
    document.head.appendChild(script);
};

export default class GoogleTagManager {
    static id: string;

    static init(id: string): void {
        loadScript(`https://www.googletagmanager.com/gtag/js?id=${id}`);
        window.dataLayer = window.dataLayer || [];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        function gtag(...args: unknown[]) {
            window.dataLayer = window.dataLayer || [];
            // eslint-disable-next-line prefer-rest-params
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', id, {
            send_page_view: true
        });
        GoogleTagManager.id = id;
        window.gtag = gtag;
    }

    static send({ action, ...options }: EventBase): void {
        if (!window.gtag) {
            return;
        }
        window.gtag('event', action, options);
    }

    static setUserId(id: string | null): void {
        if (!window.gtag) {
            return;
        }
        window.gtag('config', GoogleTagManager.id, {
            user_id: id
        });
    }

    static pageView(router: Router, payload: Record<string, unknown>): void {
        if (!window.gtag) {
            return;
        }
        const params: Record<string, unknown> = { ...payload };
        for (const name in router.currentRoute.value.params) {
            params[snakeCase(name)] = String(router.currentRoute.value.params[name]);
        }
        // SEE: https://developers.google.com/analytics/devguides/collection/ga4/page-view
        window.gtag('event', 'page_view', {
            ...params,
            page_title: router.currentRoute.value.meta?.title || document.title,
            page_location: window.location.href,
            page_path: window.location.pathname,
            send_to: GoogleTagManager.id
        });
    }
}
