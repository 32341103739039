<template>
    <div class="error-overlay">
        <div class="error-overlay__wrapper">
            <img class="image" src="@/assets/error.png" width="300" alt="Протек — Центр Внедрения" />
            <p>Попробуйте перезагрузить страницу или обратиться к администратору</p>

            <PButton variant="outline" small @click="handlerLogout"> Перезайти в систему </PButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
import PButton from '@/shared/ui/PButton/PButton.vue';
import * as ProcessAuth from '@/processes/Auth';

const handlerLogout = () => {
    ProcessAuth.logout();
    location.reload();
};
</script>

<style lang="scss" scoped>
.error-overlay {
    display: grid;
    grid-column: 1 / -1;
    justify-items: center;
    text-align: center;
    height: 100vh;

    &__wrapper {
        display: grid;
        grid-gap: 2rem;
        align-self: center;
        justify-items: center;
    }

    p {
        color: var(--gray-dark);
    }
}
</style>
