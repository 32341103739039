import { ability } from '@/shared/lib/casl/casl';
import type { CompanyId } from '@/shared/model/types/Company';
import { subject } from '@casl/ability';

export const usePermissions = () => {
    const { can } = ability;

    const canRead = (companyId?: CompanyId) =>
        can(
            'read',
            subject('AdvertisingCampaign', {
                providerId: companyId ? [companyId] : undefined
            })
        );

    const canManage = () => can('manage', 'AdvertisingCampaign');

    return {
        canRead,
        canManage
    };
};
