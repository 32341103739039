<template>
    <div class="mobile-overlay">
        <img class="logo" src="@/assets/logo.svg" width="190" alt="Протек — Центр Внедрения" />
        <h1>
            Войдите с компьютера или ноутбука
            <br />
            мобильные устройства не поддерживаются
        </h1>
    </div>
</template>

<style lang="scss" scoped>
.mobile-overlay {
    display: grid;
    grid-column: 1 / -1;
    justify-items: center;
    height: 100vh;
    background-image: var(--primary-gradient);
    text-align: center;

    img {
        align-self: center;
    }

    h1 {
        font-size: var(--text-size-16);
        color: var(--white);
    }
}
</style>
