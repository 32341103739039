import { axios } from '@/api/axios';
import type * as Auth from '@/models/Auth';
import type { Authorization } from '@/models/Auth';
import type { CompanyId } from '@/shared/model/types/Company';

/**
 * Авторизация пользователя
 */
export const login = async (payload: Auth.Login) => {
    const { data } = await axios.post<Auth.Authorization>('/api/auth/login', payload);
    return data;
};

/**
 * Регистрация пользователя
 */
export const registration = async (user: Auth.Registration) => {
    const { data } = await axios.post('/api/invite/registration', user);
    return data;
};

export async function getImpersonationUser(payload: { email: string; companyId: CompanyId }): Promise<Authorization> {
    const { data } = await axios.post<Auth.Authorization>('/api/auth/impersonate', {
        username: payload.email,
        companyId: payload.companyId
    });
    return data;
}
