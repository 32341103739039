import client from '@/shared/api/client';
import type { Permission, PermissionGroup } from '@/shared/model/types/Permission';
import type { CompanyId } from '@/shared/model/types/Company';
import type { UserId } from '@/shared/model/types/User';
import type { ReportPermission } from '@/entities/ReportPackage';
import { pick } from 'lodash';
import { toClient } from '@/shared/api/mapper/JsonApiDefaultMapper';
import { PermissionGroupDictionary, type PermissionId } from '@/shared/model/types/Permission';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toClientGroup = (data: any): PermissionGroup => {
    return {
        id: data.id,
        permissionIds: data.relationships.permissions.map((p: { id: PermissionId }) => p.id),
        ...data.attributes
    };
};

interface PermissionQueryParams {
    includes?: 'groups'[];
    group?: PermissionGroupDictionary;
}

export async function getPermissions(params: PermissionQueryParams): Promise<{
    permissions: Permission[];
    groups: PermissionGroup[];
}> {
    const response = await client.get('/api/permissions', {
        params: {
            page: 1,
            limit: 1000,
            include: params.includes?.join(','),
            'groups.key': params.group
        }
    });

    let groups: PermissionGroup[] = [];
    if (response.data.included) {
        groups = response.data.included
            .filter((item: { type: string }) => item.type === 'permissions-groups')
            .map(toClientGroup);
    }

    return {
        permissions: response.data.data.map(toClient<Permission>),
        groups
    };
}

export const getUserCompanyPermissions = async (userId: UserId, companyId: CompanyId): Promise<Permission[]> => {
    const response = await client.get(`/api/v1/users/${userId}/companies/${companyId}/permissions`);
    return response.data.data;
};

export const getPermissionsReportPackage = async (
    userId: UserId,
    companyId: CompanyId
): Promise<ReportPermission[]> => {
    const response = await client.get('/api/v1/report-packages/permissions', { params: { userId, companyId } });
    return response.data.map((item: unknown) => pick(item, ['startDate', 'endDate', 'key']));
};
