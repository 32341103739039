import queryString from 'query-string';

/**
 * Методы для сохранения query-параметров между страницами
 * Используется библиотека query-string https://www.npmjs.com/package/query-string
 * @example
 * saveQueryParamsToStorage('USER_TABLE_QUERY');
 * getSavedQueryParamsToStorage('USER_TABLE_QUERY'); =>  {
 *   currentPage: '1',
 *   endDate: '2022-06-16',
 *   itemsPerPage: '10',
 *   reportType: 'adapted',
 *   selectedAdIds: ['1500', '1446', '1489'],
 *   startDate: '2022-01-01',
 *   trendBy: 'imps'
 * }
 */

export enum StorageQueryParams {
    USER_TABLE_QUERY = 'USER_TABLE_QUERY'
}

export function saveQueryParamsToStorage(key: StorageQueryParams) {
    window.sessionStorage.setItem(key, window.location.search.slice(1));
}

export function getSavedQueryParamsToStorage(key: StorageQueryParams) {
    const search = window.sessionStorage.getItem(key);
    return queryString.parse(search ?? '');
}
