/**
 * Метод для копирования строки в буфер обмена
 */
export const copyToClipboard = async (payload: string | ClipboardItems) => {
    if (typeof payload === 'string') {
        await navigator.clipboard.writeText(payload);
    } else {
        await navigator.clipboard.write(payload);
    }
};
