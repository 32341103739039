import type { Brand } from '@/shared/model/types/Kernel';
import type { PermissionId } from '@/shared/model/types/Permission';

export type RoleId = Brand<number, 'role'>;

export enum RolesDictionary {
    /**
     * Все права (администратор)
     */
    ADMINISTRATOR_ROLE = 'ADMINISTRATOR_ROLE',

    /**
     * Роль с доступом к рекламным кампаниям и отчету по рекламным кампаниям
     */
    ADVERTISING_CABINET_ROLE = 'ADVERTISING_CABINET_ROLE',

    /**
     * Права менеджера
     */
    MANAGER_ROLE = 'MANAGER_ROLE',

    /**
     * Роль с доступом для всех отчетов b2с
     */
    REPORT_ALL_B2C_ROLE = 'REPORT_ALL_B2C_ROLE',

    /**
     * Роль с доступом для всех отчетов b2b
     */
    REPORT_ALL_B2B_ROLE = 'REPORT_ALL_B2B_ROLE',

    /**
     * Управление пользователями
     */
    USERS_ADMIN = 'USERS_ADMIN',

    /**
     * Менеджер ОСО
     */
    OSO_MANAGER_ROLE = 'OSO_MANAGER_ROLE',

    /**
     * Администратор поставщик
     */
    ADMIN_SUPPLIERS_ROLE = 'ADMIN_SUPPLIERS_ROLE',

    /**
     * Права поставщика (по дефолту у поставщика доступен только рекламный кабинет, отчеты по РК и рекламные кампании)
     */
    SUPPLIERS_ROLE = 'SUPPLIERS_ROLE'
}

export interface Role {
    id: RoleId;
    description: string;
    key: RolesDictionary;
    title: string;
    visible: boolean;
    permissionIds: PermissionId[];
    readonly createdAt: string;
    readonly updatedAt: string;
}
