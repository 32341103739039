import { IS_PRODUCTION } from '@/shared/config/env';

export enum LoggerType {
    LOG = 'log',
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error',
    DEBUG = 'debug'
}

interface LoggerOptions {
    /**
     * Типы логирования ошибок, которые будет отображаться в консоли
     */
    types?: LoggerType[];
    /**
     * Отображения всех логов
     */
    isDebug?: boolean;
}

/**
 * Функция для логирования и вывода информации в консоль
 *
 * @example
 * logger.info(campaing);
 * logger.info(`Создана рекламная кампания ${campaing.id}`, campaing);
 * logger.warn('Не передан ID рекламной кампании');
 * logger.error(error);
 */
export class Logger {
    private types: LoggerType[];
    isDebug: boolean;

    constructor(options: LoggerOptions = {}) {
        this.types = options.types ?? Object.values(LoggerType);
        this.isDebug = options.isDebug ?? false;
    }

    private event(loggerType: LoggerType, data: unknown[]) {
        if (this.isDebug || this.types.includes(loggerType)) {
            console[loggerType](...data);
        }
    }

    log(...data: unknown[]) {
        this.event(LoggerType.LOG, data);
    }

    info(...data: unknown[]) {
        this.event(LoggerType.INFO, data);
    }

    warn(...data: unknown[]) {
        this.event(LoggerType.WARN, data);
    }

    error(...data: unknown[]) {
        this.event(LoggerType.ERROR, data);
    }

    debug(...data: unknown[]) {
        this.event(LoggerType.DEBUG, data);
    }
}

const QUERY_DEBUG_STRING = '_logger_debug_';

export const logger = new Logger({
    types: IS_PRODUCTION
        ? [LoggerType.WARN, LoggerType.ERROR]
        : [LoggerType.LOG, LoggerType.INFO, LoggerType.DEBUG, LoggerType.WARN, LoggerType.ERROR],

    isDebug: new URLSearchParams(window.location.search).has(QUERY_DEBUG_STRING)
});
